export default {
    error_name_not_empty: 'The name cannot be empty.',
    error_password_not_empty: 'The password cannot be empty.',
    error_name_too_long: 'Profile name is too long.',
    create_user: 'Create new profile',
    name: 'Name',
    surname: 'Surname',
    email: 'Email',
    password: 'Profile Password',
    password_repeat: 'Repeat Password',
    createBtn: 'Create Profile',
    sort: 'Sort',
    frequently_used: 'Last logged in',
    exp: 'Experience Points',
    logout: 'Logout',
    profile_overview: 'Back to Login',
    invalidMail: 'Invalid Email.',
    vendor_number: 'Client Number',
    error_password_not_matching: 'The passwords do not match.',
    change_password: 'Change password',
    set_new_password: 'Set a new password',
    change_password_desc: 'Enter a new password for your profile now!',
    change_language: 'Change language to',
    change_password_min_error: 'At least 7 chars',
    change_password_req_error: 'Required',
    error_customer_number: 'The customer number is incomplete.',
    points_info:
        'Congratulations, you have successfully worked through all the content. The remaining points are already reserved for new content. Look forward to it.'
}
