import styled from 'styled-components'

export const Transcript = styled.div`
    width: 100%;
    background: white;
    border-radius: 20px;
    overflow: hidden;
    padding: 20px 40px;

    box-sizing: border-box;
    position: relative;
`
