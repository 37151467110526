export default {
    overview: 'Abschlusstests',
    finaltest: 'Abschlusstests',
    explanation:
        'Mit diesen Abschlusstests kannst du beweisen, was du drauf hast. Mit jedem bestandenen Test steigt dein Level und du bekommst ein Zertifikat für deine Leistungen per Email.',
    completed_at: 'Abgeschlossen am',
    completed: 'Abgeschlossen',
    ongoing: 'Neu',
    nothinghere_available:
        'Keine weiteren Tests verfügbar. Sei gespannt auf das nächste Content Update!',
    nothinghere_completed:
        'Keine Tests abgeschlossen. Sobald du einen Test freigeschalten hast, kannst du dir dein Zertifikat verdienen!',
    tooltip_locked:
        'Abschlusstests können erst gestartet werden, wenn alle Lektionen der Kontaktstelle abgeschlossen sind.',
    locked: 'Noch nicht freigeschaltet',
    finalquiz_start_btn: 'Abschlusstest starten',
    disclaimer_correct_answer:
        'Kreuze die richtigen Antworten an. Es können mehrere Antworten richtig sein. Beantwortet ist eine Frage, wenn alle richtigen und keine falschen Antworten angekreuzt sind.',
    quiz_passed:
        'Herzlichen Glückwunsch, du hast den Test bestanden. Dein Zertifikat kommt gleich per Mail.',
    quiz_failed: 'Das war leider nichts, du kannst aber gleich nochmal probieren',
    noPoints: 'Du hast den Test bereits einmal absolviert, deswegen gibt es keine neuen Punkte',
    question: 'Frage',
    question_plural: 'Fragen',
    repeat_test: 'Test wiederholen',
    evaluation: 'Auswertung'
}
