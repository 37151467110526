import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment
} from '@material-ui/core'
import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { useResetPasswordMutation } from '../../../generated/graphql'
import { toast } from 'react-toastify'
import { removeUser } from '../../../Utils/setUser'

import { Field, Form } from 'react-final-form'

import { TextField } from 'final-form-material-ui'

function ChangePasswordModal({
    open,
    handleClose,
    force
}: {
    open: boolean
    handleClose: () => void
    force: boolean
}) {
    const { t } = useTranslation()
    const [showPassword, setShowPassword] = useState(false)

    const handleMouseDownPassword = () => setShowPassword(!showPassword)
    const handleClickShowPassword = () => setShowPassword(!showPassword)
    const [resetPassword] = useResetPasswordMutation()

    const onSubmit = async (values: any) => {
        const result = await resetPassword({
            variables: {
                newPassword: values.password
            }
        })
        if (result.errors) {
            toast.error(result.errors.toString)
        } else {
            toast.success('Passwort wurde geändert! Bitte logge dich erneut ein')
            removeUser()
            window.location.reload()
        }
        handleClose()
    }

    const validate = (values: any) => {
        console.log('validate')
        const errors = {} as any
        if (!values.password || values.password.length <= 7) {
            errors.password = t('profile:change_password_min_error')
        }
        if (!values.repeatedPassword) {
            errors.repeatedPassword = t('profile:change_password_req_error')
        }
        if (values.password !== values.repeatedPassword) {
            errors.repeatedPassword = t('profile:error_password_not_matching')
        }
        return errors
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                disableBackdropClick={force}
                disableEscapeKeyDown={force}>
                <Form
                    onSubmit={onSubmit}
                    validate={validate}
                    initialValues={{ password: '', repeatedPassword: '' }}
                    render={({ handleSubmit, values, form }) => (
                        <form onSubmit={handleSubmit}>
                            <DialogTitle id="form-dialog-title">
                                {force
                                    ? t('profile:set_new_password')
                                    : t('profile:change_password')}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {t('profile:change_password_desc')}
                                </DialogContentText>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Field
                                            variant="outlined"
                                            autoFocus
                                            component={TextField}
                                            fullWidth
                                            name="password"
                                            label={t('profile:password')}
                                            type={showPassword ? 'text' : 'password'}
                                            InputProps={{
                                                // <-- This is where the toggle button is added.
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}>
                                                            {showPassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            variant="outlined"
                                            fullWidth
                                            component={TextField}
                                            name="repeatedPassword"
                                            label={t('profile:password_repeat')}
                                            type={showPassword ? 'text' : 'password'}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                {!force && (
                                    <Button onClick={handleClose} color="primary">
                                        {t('base:cancel')}
                                    </Button>
                                )}
                                <Button type="submit" color="primary">
                                    {t('base:submit')}
                                </Button>
                            </DialogActions>
                        </form>
                    )}
                />
            </Dialog>
        </React.Fragment>
    )
}

export default ChangePasswordModal
