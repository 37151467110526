import { ApolloClient, ApolloLink, concat, HttpLink, InMemoryCache, split } from '@apollo/client'
import { createTheme } from '@material-ui/core'
import { loadAuthTokenFromLocalStorage } from './Utils/setUser'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { toast } from 'react-toastify'
import { captureException } from '@sentry/react'

const loadHeaders = () => {
    const profileOnlyToken = loadAuthTokenFromLocalStorage()
    if (profileOnlyToken) {
        return {
            'expected-role': 'iqlab-user',
            'User-Authorization': `Basic ${profileOnlyToken}`
        }
    }
}

const fetcher = (...args: any) => {
    // @ts-ignore
    return window.fetch(...args)
}

const getBaseDomain = () => {
    const hasuraBaseDomain = process.env.REACT_APP_HASURA_BASE_DOMAIN
    console.log(`Using Hasura base domain: ${hasuraBaseDomain}`)
    if (!hasuraBaseDomain) throw new Error('HASURA_BASE_DOMAIN is not defined')
    return hasuraBaseDomain
}

const baseDomain = getBaseDomain()

const useTls = baseDomain.includes('localhost') ? '' : 's'

const httpUri = `http${useTls}://${baseDomain}/v1/graphql`
const wsUri = `ws${useTls}://${baseDomain}/v1/graphql`

const httpLink = new HttpLink({
    uri: httpUri,
    fetch: fetcher
})

const wsLink = new WebSocketLink({
    uri: wsUri,
    options: {
        reconnect: true,
        lazy: true,
        connectionParams: {
            headers: loadHeaders()
        }
    }
})

const errorLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(({ data, errors }) => {
        if (errors) {
            errors.forEach((error) => {
                if (!error) return

                if (error.message.includes("not found in type: 'query_root'")) {
                    console.error('Authentication is not valid')
                    toast.error('Authentication Error. Please try to login again.')
                    // resetAuth()
                } else {
                    toast('API Error occurred: ' + error.message, { type: 'error' })
                        console.error(`GraphQL error: ${error.path} -> ${error.message}`, error)
                    // Capture Sentry Exception
                    captureException(error)
                }

                // if (error.name === TokenExpired) {
                //     history.replace('/login/destroy?error=token')
                // } else if (
                //     !(
                //         whitelistedErrors.includes(operation.operationName) ||
                //         whitelistedErrors.includes(error.name)
                //     )
                // ) {
                //     const key = operation.operationName + error.name
                //     //TODO: Toast
                //     // errorCache.push({
                //     //     key,
                //     //     title: error.name,
                //     //     Query: operation.operationName,
                //     //     message: `${error.name} -> ${error.message}`
                //     // })
                //     // renderErrors(errorCache)
                // }
            })
        }
        return { data, errors }
    })
})

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query)
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink,
    concat(errorLink, httpLink)
)

const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({
        headers: loadHeaders()
    })
    return forward(operation)
})

const whitelistedErrors = ['Login']

export const client = new ApolloClient({
    link: concat(authMiddleware, splitLink),
    cache: new InMemoryCache()
})
export const theme = createTheme({
    palette: {
        primary: {
            main: '#000000',
            light: '#ef7103',
            dark: '#ffffff'
        },
        secondary: {
            main: '#F5F5FB;'
        }
    }
})
