export default {
    error_name_not_empty: 'De naam mag niet leeg zijn.',
    error_password_not_empty: 'Het wachtwoord mag niet leeg zijn.',
    error_name_too_long: 'Profiel naam is te lang.',
    create_user: 'Nieuw profiel aanmaken',
    name: 'Voornaam',
    surname: 'Achternaam',
    email: 'E-mail adres',
    password: 'Profiel wachtwoord',
    password_repeat: 'Herhaal wachtwoord',
    createBtn: 'Profiel aanmaken',
    sort: 'Sorteren',
    frequently_used: 'Laatst ingelogd',
    exp: 'Ervaringspunten',
    logout: 'Log uit',
    profile_overview: 'Terug naar Inloggen',
    invalidMail: 'Ongeldig e-mail adres',
    vendor_number: 'Klantnummer',
    error_password_not_matching: 'De wachtwoorden komen niet overeen.',
    change_password_desc: 'Voer nu een nieuw wachtwoord voor uw profiel in!',
    change_password: 'Wijzig wachtwoord',
    set_new_password: 'Nieuw wachtwoord instellen',
    change_language: 'Verander de taal in',
    change_password_min_error: 'Ten minste 7 tekens',
    change_password_req_error: 'Vereist',
    error_customer_number: 'Het klantnummer is onvolledig.',
    points_info:
        'Gefeliciteerd, je hebt alle inhoud met succes doorgewerkt. De resterende punten zijn al gereserveerd voor nieuwe inhoud. Kijk ernaar uit.'
}
