import { useTranslation } from 'react-i18next'
import { PageTitleWrapper } from '../course/PageTitleWrapper'
import { Typography } from '@material-ui/core'
import React from 'react'
import { useGetContactpointViewDataQuery } from '../../../generated/graphql'
import FullWidthDescriptionCard, {
    DescriptionCardType
} from '../DescriptionCardComponents/FullWidthDescriptionCard'
import { ActiveFinalQuizPath } from '../../../router/RouteConstants'
import { InfoComponentSubsection } from '../DescriptionCardComponents/DescriptionCardElements/InfoComponent'
import { useCustomTrans } from '../../../i18n/UseCustomTrans'

import { uniformLection } from '../home/DataMapper'

export enum SectionNames {
    'INTRO' = 'intro',
    'PELVIS' = 'pelvis',
    'HAND' = 'hand',
    'FOOT' = 'foot',
    'PROFILER' = 'profiler',
    'PRODUCTS' = 'products',
    'VIDEO' = 'video'
}

function uniformFinalQuiz(quiz: any, translatedTitle: string) {
    if (quiz?.finalQuiz === null || quiz?.finalQuiz === undefined) {
        return undefined
    }
    const constTotalMinRequired = 10
    const transformedQuizInfo: DescriptionCardType = {
        isLocked: quiz?.isQuizLocked,
        redirectUrl: `${ActiveFinalQuizPath}/${quiz?.finalQuiz?.id}`,
        imgUrl: quiz?.finalQuiz?.imgUrl,
        title: translatedTitle,
        totalMinRequired: quiz.isCompleted ? 0 : constTotalMinRequired,
        totalCount: quiz?.finalQuiz?.questionsAggregate.aggregate.count,
        isCompleted: quiz?.isCompleted,
        newChapterCount: undefined
    }
    return transformedQuizInfo
}

export default function SectionComponent({
    contactPointName,
    contactPointIds,
    newChapterCount
}: {
    contactPointName: string
    contactPointIds: number[]
    newChapterCount: { [key: number]: number } | undefined
}) {
    const { t } = useTranslation()
    const tr = useCustomTrans()

    const { data } = useGetContactpointViewDataQuery({
        variables: { contactPointId: contactPointIds },
        fetchPolicy: 'network-only'
    })

    const contentToDisplay: {
        uniformedLection: DescriptionCardType
        componentSubsection: InfoComponentSubsection
    }[] = []

    // build array of lections and final quizzes according to the order in which the should be displayed
    data?.progress_contactpoint_overview.forEach((subContactPoint) => {
        // add all lections (in uniformed way) to contentToDisplay
        subContactPoint.lectionsOverview.forEach((lection) => {
            contentToDisplay.push({
                uniformedLection: uniformLection(
                    lection,
                    tr(lection?.lection?.titleI18n),
                    newChapterCount?.[lection?.lectionId ?? -1]
                ),
                componentSubsection: InfoComponentSubsection.CHAPTER
            })
        })

        //if available: store final quiz (in uniformed way) in contentToDisplay
        const uniformedFinalQuiz = uniformFinalQuiz(
            subContactPoint,
            tr(subContactPoint.finalQuiz?.titleI18n)
        )
        if (uniformedFinalQuiz) {
            contentToDisplay.push({
                uniformedLection: uniformedFinalQuiz,
                componentSubsection: InfoComponentSubsection.QUESTIONS
            })
        }
    })

    return (
        <>
            <PageTitleWrapper>
                <Typography variant="h4">{t(`base:${contactPointName}`)}</Typography>
            </PageTitleWrapper>
            {contentToDisplay &&
                contentToDisplay.map((content) => (
                    <FullWidthDescriptionCard
                        itemDetails={content.uniformedLection}
                        infoComponentSubsectionName={content.componentSubsection}
                    />
                ))}
        </>
    )
}
