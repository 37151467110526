import styled from 'styled-components'

export const DrawerPlaylist = styled.div`
    /* min-width: 350px;
  max-width: 350px; */
    width: 100%;
    top: 0px;
    bottom: 0;
    /* position: sticky; */
    left: 0;
    background: white;
    border-radius: auto;
    margin-right: auto;
    box-sizing: border-box;
    padding: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`
