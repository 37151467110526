import { DescriptionCardType, useDescriptionCardStyles } from './FullWidthDescriptionCard'
import { Card } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InfoComponent, { InfoComponentSubsection } from './DescriptionCardElements/InfoComponent'
import { LockedCardImageComponent } from '../course/LockedCardImageComponent'
import TitleComponent from './DescriptionCardElements/TitleComponent'
import CircularProgressIndicator from './DescriptionCardElements/CircularProgressIndicator'

export const useHalfWidthDescriptionCard = makeStyles({
    halfWidthCard: {
        display: 'grid',
        gridTemplateRows: 'auto',
        height: '40vh'
    },
    imageStyle: {
        gridColumn: 'span 2',
        height: '20vh',
        margin: '1em'
    },
    circularProgressIndicator: {
        gridRow: 'span 2',
        padding: '1em',
        paddingLeft: 0,
        marginLeft: 'auto',
        marginRight: '2em'
    },
    titleStyle: {
        paddingLeft: '2em',
        alignSelf: 'end'
    },
    infoStyle: {
        paddingLeft: '2em',
        alignSelf: 'start'
    }
})

export default function HalfWidthDescriptionCard({
    itemDetails,
    infoComponentSubsectionName
}: {
    itemDetails: DescriptionCardType
    infoComponentSubsectionName: InfoComponentSubsection
}) {
    //necessary definitions
    const history = useHistory()
    const { t } = useTranslation()

    //import styles
    const cardStyle = useDescriptionCardStyles()
    const halfWidthCardStyle = useHalfWidthDescriptionCard()

    const { isLocked, redirectUrl, isCompleted, completedCount, totalCount, imgUrl, title } =
        itemDetails

    // only make Card grey if the lection is marked as completed (only contains whether fundamental lections are finished)
    // and all lections are completed
    const markAsFinished = isCompleted && (completedCount ?? 0) === totalCount

    return (
        <Card
            variant="outlined"
            onClick={!isLocked ? () => history.push(redirectUrl) : undefined}
            className={`${cardStyle.root} ${halfWidthCardStyle.halfWidthCard}`}
            style={{
                opacity: markAsFinished ? 0.5 : 1
            }}>
            <LockedCardImageComponent
                isLocked={isLocked}
                lockedText={t('courses:locked')}
                lockedToolTip={t('courses:tooltip_locked')}
                img_url={imgUrl}
                title={title}
                className={halfWidthCardStyle.imageStyle}
            />
            <TitleComponent
                titleInfo={itemDetails}
                className={`${halfWidthCardStyle.titleStyle}`}
            />
            <CircularProgressIndicator
                progressInfo={itemDetails}
                className={halfWidthCardStyle.circularProgressIndicator}
            />
            <InfoComponent
                infoDetails={itemDetails}
                subsectionName={infoComponentSubsectionName}
                className={`${halfWidthCardStyle.infoStyle}`}
                flexDirection={'row'}
            />
        </Card>
    )
}
