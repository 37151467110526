import { Card } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import InfoComponent, { InfoComponentSubsection } from './DescriptionCardElements/InfoComponent'
import { LockedCardImageComponent } from '../course/LockedCardImageComponent'
import TitleComponent from './DescriptionCardElements/TitleComponent'
import CircularProgressIndicator from './DescriptionCardElements/CircularProgressIndicator'
import HalfWidthDescriptionCard from './HalfWidthDescriptionCard'
import { START_DIFFERENT_CARD_TYPES } from '../../../App'

export type DescriptionCardType = {
    isLocked: boolean
    redirectUrl: string
    imgUrl: string
    title: string
    totalMinRequired: number
    completedCount?: number
    totalCount: number
    isCompleted: boolean
    newChapterCount: number | undefined
}

export const useDescriptionCardStyles = makeStyles({
    root: {
        margin: '1em',
        marginLeft: 0,
        height: 'auto',
        borderRadius: '20px',
        cursor: 'pointer'
    },
    media: {
        width: '100%',
        flexGrow: 1
    },
    action: {
        height: '100%',
        display: 'flex',
        width: '100%'
    },
    muicontent: {
        padding: '10px',
        boxSizing: 'border-box',
        flexGrow: 7
    },
    fullWidthDescriptionCard: {
        display: 'grid',
        gridTemplateColumns: '2fr 4fr 1.5fr 1fr'
    },
    frameElement: {
        alignSelf: 'center',
        justifyContent: 'center',
        padding: '1em'
    }
})

export default function FullWidthDescriptionCard({
    itemDetails,
    infoComponentSubsectionName
}: {
    itemDetails: DescriptionCardType
    infoComponentSubsectionName: InfoComponentSubsection
}) {
    const windowWidth = window.innerWidth
    return (
        <>
            {windowWidth < START_DIFFERENT_CARD_TYPES ? (
                <HalfWidthDescriptionCard
                    itemDetails={itemDetails}
                    infoComponentSubsectionName={infoComponentSubsectionName}
                />
            ) : (
                <FullWidthDesign
                    itemDetails={itemDetails}
                    infoComponentSubsectionName={infoComponentSubsectionName}
                />
            )}
        </>
    )
}

function FullWidthDesign({
    itemDetails,
    infoComponentSubsectionName
}: {
    itemDetails: DescriptionCardType
    infoComponentSubsectionName: InfoComponentSubsection
}) {
    //necessary definitions
    const history = useHistory()
    const { t } = useTranslation()

    //import styles
    const cardStyle = useDescriptionCardStyles()

    // parameter extraction
    let { isLocked, redirectUrl, imgUrl, title, isCompleted, totalCount, completedCount } =
        itemDetails

    // If Card is used to display a final quiz completed Count is undefined to ensure that the behaviour (e.g. grey overlay) is only depending on the isCompleted variable
    // completedCount is set to totalCount to make sure completedCount >= totalCount always evaluates to true
    if (completedCount === undefined) {
        if (infoComponentSubsectionName === InfoComponentSubsection.QUESTIONS) {
            completedCount = totalCount
        } else {
            completedCount = 0
        }
    }

    // only make Card grey if the lection is marked as completed (only contains whether fundamental lections are finished)
    // and all lections are completed
    const markAsFinished = isCompleted && completedCount === totalCount

    return (
        <Card
            variant="outlined"
            onClick={!isLocked ? () => history.push(redirectUrl) : undefined}
            className={`${cardStyle.fullWidthDescriptionCard} ${cardStyle.root}`}
            style={{ opacity: markAsFinished || isLocked ? 0.5 : 1 }}>
            <LockedCardImageComponent
                isLocked={isLocked}
                lockedText={t('courses:locked')}
                lockedToolTip={t('courses:tooltip_locked')}
                img_url={imgUrl}
                title={title}
            />
            <TitleComponent titleInfo={itemDetails} className={cardStyle.frameElement} />
            <InfoComponent
                infoDetails={itemDetails}
                subsectionName={infoComponentSubsectionName}
                className={cardStyle.frameElement}
            />
            <CircularProgressIndicator
                progressInfo={itemDetails}
                className={cardStyle.frameElement}
            />
        </Card>
    )
}
