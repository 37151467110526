import styled from 'styled-components'

export const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: fit-content;
  max-width: ${window.innerWidth};
  .MuiCard-root{
    margin: 1em
  }
`
