import { AccessTime, BookmarkBorder, CollectionsBookmark, HelpOutline } from '@material-ui/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

export enum InfoComponentSubsection {
    LECTION,
    CHAPTER,
    QUESTIONS
}

export type InfoComponentType = {
    totalMinRequired: number
    completedCount?: number
    totalCount: number
}

export const useLectionInfoStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    elements: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '0.5em',
        marginBottom: '0.5em'
    },
    text: {
        marginLeft: '0.3em'
    }
})

export default function InfoComponent({
    infoDetails,
    subsectionName,
    className,
    flexDirection = 'column'
}: {
    infoDetails: InfoComponentType
    subsectionName: InfoComponentSubsection
    className?: string
    flexDirection?: any
}) {
    //necessary definitions
    const { t } = useTranslation()
    const lectionInfoStyles = useLectionInfoStyles()

    // parameter extraction
    const { totalMinRequired, completedCount, totalCount } = infoDetails

    const rowAlignment = flexDirection === 'row'

    // Different label types for indicating number of done lections / chapters / questions
    // According to the subsection name the correct icon and text is selected as label
    let subsectionLabel = <></>
    switch (subsectionName) {
        case InfoComponentSubsection.LECTION:
            subsectionLabel = (
                <>
                    <CollectionsBookmark />
                    <p className={lectionInfoStyles.text}>
                        {`${t('base:lection')} ${completedCount}/${totalCount}`}
                    </p>
                </>
            )
            break
        case InfoComponentSubsection.CHAPTER:
            subsectionLabel = (
                <>
                    {' '}
                    <BookmarkBorder />
                    <p className={lectionInfoStyles.text}>
                        {`${t('base:chapter')} ${completedCount}/${totalCount}`}
                    </p>
                </>
            )
            break
        case InfoComponentSubsection.QUESTIONS:
            subsectionLabel = (
                <>
                    <HelpOutline />
                    <p className={lectionInfoStyles.text}>
                        {`${totalCount} ${
                            totalCount === 1
                                ? t('finalquiz:question', { count: totalCount })
                                : t('finalquiz:question_plural', { count: totalCount })
                        }
                `}
                    </p>
                </>
            )
            break
    }

    return (
        <div
            className={`${lectionInfoStyles.container} ${className}`}
            style={rowAlignment ? { flexDirection: flexDirection, justifyContent: 'start' } : {}}>
            {totalMinRequired !== undefined && (
                <div className={lectionInfoStyles.elements}>
                    <AccessTime />
                    <p className={lectionInfoStyles.text}>
                        {`${totalMinRequired} ${t('courses:minutes')}`}
                    </p>
                </div>
            )}
            {totalCount !== undefined && (
                <div
                    className={lectionInfoStyles.elements}
                    style={rowAlignment ? { marginLeft: '2em' } : {}}>
                    {subsectionLabel}
                </div>
            )}
        </div>
    )
}
