import { Button, Card, CardActions, CardContent, makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
    GetAllLectionsForOverviewDocument,
    QuizFragment,
    useCreateQuizSessionMutation,
    useEvaluateQuizMutation,
    useInsertQuizAnswersMutation
} from '../../generated/graphql'
import { useTranslation } from 'react-i18next'
import { shuffleArray } from '../../Utils/shuffleArray'
import { toast } from 'react-toastify'
import { useCustomTrans } from '../../i18n/UseCustomTrans'
import StyledButton from '../../Components/NonLogicalComponents/StyledButton'
import { MultipleChoiceQuestion } from '../quiz/MultipleChoiceQuestion'

interface CourseActiveQuizCardParams {
    lectionId: number
    chapterId: number
    quiz?: QuizFragment
    onFinished: (gainedExpPoints: number) => void
    onCanceled: () => void
}

/**
 * Provides the interface for a comprehension quiz in a given chapter
 */
export function CourseActiveQuizCard({ onCanceled, quiz, onFinished }: CourseActiveQuizCardParams) {
    const { t } = useTranslation('courses')
    const tr = useCustomTrans()
    const classes = useStyles()
    const [activeAnswers, setActiveAnswers]: [number[], any?] = useState([])
    const [waitingForEval, setWaitingForEval] = useState(false)
    const [success, setSuccess]: [boolean?, any?] = useState(undefined)
    const question = quiz?.questions[0]
    const [shuffeledChoices, setShuffeledChoices] = useState([] as any[])

    useEffect(
        () => setShuffeledChoices(shuffleArray(question?.choices.slice(0) ?? [])),
        [setShuffeledChoices, quiz]
    )

    const [newSession] = useCreateQuizSessionMutation()
    const [mutateAnswer] = useInsertQuizAnswersMutation()
    const [evaluate, { data }] = useEvaluateQuizMutation({
        refetchQueries: [{ query: GetAllLectionsForOverviewDocument }]
    })

    const handleAnswer = async () => {
        if (activeAnswers === undefined || activeAnswers?.length === 0 || !quiz || !question) {
            return
        }

        const data = await newSession({
            variables: { quiz_id: quiz.id }
        })
        const sessionID = data?.data?.session?.id

        if (!sessionID) {
            console.error('Failed to create session', data.errors)
        }

        let answers = activeAnswers.map((answer: number) => {
            return {
                session_id: sessionID,
                choice_id: shuffeledChoices[answer]['id']
            }
        })

        setWaitingForEval(true)
        const result = await mutateAnswer({
            variables: {
                session_id: sessionID,
                question_id: question.id,
                objects: answers
            }
        })

        await evaluateAnswer(sessionID)
    }

    async function evaluateAnswer(sessionID: string) {
        const { data } = await evaluate({ variables: { session_id: sessionID } })
        setWaitingForEval(false)
        if (data?.evaluateQuiz?.passed) {
            onFinished(data?.evaluateQuiz?.expPoints)
        } else {
            setActiveAnswers([])
            setSuccess(false)
            toast.error(t('courses:toast_quiz_answer_wrong'))
            //Re-Shuffle
            setShuffeledChoices(shuffleArray(shuffeledChoices))
        }
    }

    return (
        <Layout>
            <TestCardWrapper>
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Typography variant="body1" component="h3">
                            {t('title_quiz')}
                        </Typography>
                        <br />
                        {quiz && question && (
                            <MultipleChoiceQuestion
                                key={quiz?.id}
                                question={question}
                                shuffledChoices={shuffeledChoices}
                                activeAnswers={activeAnswers}
                                setActiveAnswers={setActiveAnswers}
                            />
                        )}

                        {success !== undefined && (
                            <Typography variant="body1">
                                {success ? t('courses:quiz_right') : t('courses:quiz_wrong')}
                            </Typography>
                        )}
                    </CardContent>

                    <CardActions className={classes.buttons}>
                        <Button onClick={() => onCanceled()} size="small">
                            {t('courses:quiz_cancel')}
                        </Button>
                        {waitingForEval ? (
                            <CircularProgress />
                        ) : (
                            <StyledButton
                                disabled={activeAnswers === undefined}
                                onClick={() => handleAnswer()}>
                                {t('courses:quiz_submit')}
                            </StyledButton>
                        )}
                    </CardActions>
                </Card>
            </TestCardWrapper>
        </Layout>
    )
}

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '20px'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)'
    },
    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between'
    }
})

const Layout = styled.div`
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-flex: 1;
    /* position: relative; */
    //height: 800px;
    width: 100%;
    flex-shrink: 0;
`

const TestCardWrapper = styled.div`
    width: 100%;
    background: white;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
`
