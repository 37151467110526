import React, { useState } from 'react'
import VerticalMenu from './layout/VerticalMenu'
import MenuIcon from '@material-ui/icons/Menu'
import { Close } from '@material-ui/icons'
import { RouteDefinition } from '../../router/routes'
import { getScreenWidth } from '../../App'

export function MobileLayout(props: { routeDefinitions: Array<RouteDefinition>; children: any }) {
    const [showMenu, setShowMenu]: ['hidden' | 'visible', any] = useState('hidden')

    function changeMenuVisibility() {
        switch (showMenu) {
            case 'hidden': {
                setShowMenu('visible')
                return showMenu
            }
            case 'visible': {
                setShowMenu('hidden')
                return showMenu
            }
        }
    }

    return (
        <div style={{ marginBottom: '2em' }}>
            <div
                style={{
                    visibility: showMenu,
                    position: 'fixed',
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    top: '0',
                    left: '0',
                    right: '0',
                    bottom: '0',
                    zIndex: '998',
                    backgroundColor: 'white'
                }}>
                {
                    <VerticalMenu
                        routes={props.routeDefinitions.filter((it) => !it.mobileOnly)}
                        key="meinMenu"
                        changeMenuVisibility={changeMenuVisibility}
                    />
                }
            </div>
            <div
                style={{ position: 'fixed', left: 0, top: 0, fontSize: '0.5em', zIndex: '999' }}
                onClick={changeMenuVisibility}>
                <div style={{ backgroundColor: 'white', display: 'flex', width: getScreenWidth() }}>
                    {showMenu === 'hidden' ? (
                        <div>
                            <MenuIcon style={{ margin: '0.5em' }} />
                            <img
                                src={'/iqlab_logo.svg'}
                                style={{ height: '5em', alignSelf: 'center' }}
                            />
                        </div>
                    ) : (
                        <Close style={{ margin: '0.5em' }} />
                    )}
                </div>
            </div>
        </div>
    )
}
