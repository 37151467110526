import React from 'react'

function SQLogo() {
    return (
        <div style={{ display: 'flex', justifyItems: 'center', alignItems: 'center', height: '70px' }}>
            <img src="/iqlab_logo.svg" alt="logo"/>
        </div>
    )
}

export default SQLogo
