export default {
    information: 'INFORMATION',
    service: 'SERVICE',
    followUs: 'FOLLOW US',
    imprint: 'Imprint',
    terms: 'Terms',
    privacy: 'Privacy',
    returns: 'Right To Withdraw',
    contact: 'Contact',
    account: 'Account',
    newsletter: 'Newsletter'
}
