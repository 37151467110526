export const HomePath = '/home'
export const HandPath = '/hand'
export const FootPath = '/foot'
export const PelvisPath = '/pelvis'
export const ProductsPath = '/products'
export const IntroPath = '/intro'
export const ProfilerPath = '/profiler'
export const InstallationVideoPath = '/video'

export const ActiveCoursePath = '/courses_active'
export const ActiveFinalQuizPath = '/quiz_active'
