import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { useTranslation } from 'react-i18next'
import trophy from '../../../assets/trophy.gif'

const SuccButton = styled(Button)`
    background: var(--sq-orange) !important;
    color: white !important;
`

const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 20px !important;
    text-align: center;
`

const GamificationCounter = styled.p`
    color: var(--sq-orange);
    font-size: 30px;
    font-weight: bold;
`

interface CourseRewardModalProps {
    open: boolean
    onClosed: () => void
    gainedExp: number
}

export default function CourseRewardModal({ open, onClosed, gainedExp }: CourseRewardModalProps) {
    const classes = useStyles()
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle)
    const { t } = useTranslation('rewardsModal')

    const handleClose = () => {
        //setOpen(false)
        onClosed()
    }

    // @ts-ignore
    const body = (
        <div style={modalStyle} className={classes.paper}>
            <ModalBody>
                <img width="300px" src={trophy} alt="" className="animated" />
                <br />
                <h1 id="simple-modal-title">{t('rewardsModal:succText')}</h1>
                <br />
                <br />
                <p id="simple-modal-description" style={{ maxWidth: '300px' }}>
                    {t('rewardsModal:succSubText')}
                </p>
                <br />
                <GamificationCounter>+{gainedExp} P</GamificationCounter>
                {gainedExp === 0 && (
                    <p
                        style={{
                            fontWeight: 'lighter',
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap'
                        }}>
                        <InfoIcon /> <span>{t('rewardsModal:noPoints')}</span>
                    </p>
                )}

                <br />
                <SuccButton onClick={() => onClosed()} className={classes.root}>
                    {t('rewardsModal:finishButton')}
                </SuccButton>
            </ModalBody>
        </div>
    )

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                {body}
            </Modal>
        </div>
    )
}

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    }
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            borderRadius: '20px',
            outline: 'none',
            position: 'absolute',
            backgroundColor: 'rgb(247,247,247)',
            padding: theme.spacing(2, 4, 3)
        },
        root: {
            borderRadius: '20px',
            padding: theme.spacing(1, 4, 1)
        }
    })
)
