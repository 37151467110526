export default {
    overview: 'Overview',
    startTest: 'Start the quiz',
    nextChapter: 'Next Chapter',
    allcourses: 'All Courses',
    new: 'New',
    new_plural: 'New',
    ongoing: 'New',
    completed: 'Completed',
    no_courses: 'There are no more lessons available.',
    nothinghere_completed: 'No courses completed yet. Get started with a new course!',
    nothinghere_available: 'No more courses available. Be excited for the next content update!',
    quiz_wrong: 'Sorry, but the answer was wrong.',
    title_quiz: 'Comprehension Quiz',
    quiz_right: 'The answer was correct!',
    quiz_submit: 'Submit',
    quiz_cancel: 'Cancel',
    tooltip_locked:
        'This course cannot be started yet because not all requirements have been completed.',
    locked: 'Not unlocked yet',
    toast_quiz_answer_wrong: 'Unfortunately, this answer was wrong!',
    toast_quiz_answer_exp: '\uD83C\uDFC6 Very good! +{{gainedExpPoints}} experience points',
    toast_quiz_answer_already_done: 'You have already answered the question correctly once!',
    chapters: 'Chapters',
    requirements: 'Requirements',
    continue: 'Continue',
    successfully_completed: 'Successfully completed',
    minutes: 'Minutes',
    get_started: 'Get started'
}
