import { Link } from '@material-ui/core'
import React from 'react'
import Typography from '@material-ui/core/Typography'

export function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.sq-lab.com/" target="_blank">
                SQlab GmbH
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}
