import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import de from './locales/de/de'
import en from './locales/en/en'
import nl from './locales/nl/nl'

i18n.use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: 'en', // use en if detected lng is not available
        keySeparator: false,
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        debug: true,
        resources: {
            de,
            en,
            nl
        },
        supportedLngs: ['en', 'de', 'nl', 'dev'],
        react: {
            bindI18n: 'loaded languageChanged',
            bindI18nStore: 'added',
            transEmptyNodeValue: '',
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
            useSuspense: true
        }
    })

export default i18n
