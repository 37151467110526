import React, { useState } from 'react'
import styled from 'styled-components'
import { Stream } from '@cloudflare/stream-react'
import AspectRatio from '../AspectRatio'
import { Skeleton } from '@material-ui/lab'

const VideoPlayer = styled.div`
    width: 100%;
    height: 100%;
    maxWidth: window.innerWidth;
    border-radius: 20px;
    overflow: hidden !important;
`

function CourseVideo({ videoID, autoplay, thumbnailLink }: { videoID: string, autoplay: boolean, thumbnailLink?: string}) {
    const [loading, setLoading] = useState(true)

    return (
        <div className="paddingOnMobile" style={{ marginBottom: '20px', maxWidth: window.innerWidth }}>
            <AspectRatio ratio={16 / 9}>
                <VideoPlayer>
                    {videoID && (
                        <Stream
                            controls
                            src={videoID}
                            key={videoID}
                            height="100%"
                            width='auto'
                            onError={() => console.error('Video error', videoID)}
                            onCanPlay={() => setLoading(false)}
                            autoplay={autoplay}
                            poster={thumbnailLink ?? ''}
                        />
                    )}
                    {(!videoID || loading) && <Skeleton variant="rect" height="100%" />}
                </VideoPlayer>
            </AspectRatio>
        </div>
    )
}

export default CourseVideo
