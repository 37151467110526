import React from 'react'
import { Button, Card, makeStyles, Typography } from '@material-ui/core'
import styled from 'styled-components'
import CustomSanityBlock from '../../Components/NonLogicalComponents/CustomSanityBlock'
import { useTranslation } from 'react-i18next'

export function FinalQuizStartCard({ onClicked, title, paragraph, imageURL }: any) {
    const classes = useStyles()
    const { t } = useTranslation('finalquiz')
    return (
        <Layout>
            <TestCardWrapper>
                <Card className={classes.root} variant="outlined">
                    <CardLayout>
                        <img className="titleImage" src={imageURL! + '?h=500'} alt="" />
                        <TitleSection>
                            <Typography className="" variant="h4">
                                {title!}
                            </Typography>
                            <br />
                            <CustomSanityBlock blocks={paragraph} />
                            <br />
                            <br />
                            <br />
                            <SuccButton onClick={() => onClicked()} className={classes.button}>
                                {t('finalquiz:finalquiz_start_btn')}
                            </SuccButton>
                        </TitleSection>
                    </CardLayout>
                </Card>
            </TestCardWrapper>
        </Layout>
    )
}

const TitleSection = styled.div`
    text-align: center;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    align-items: center;

    & > .title_underline {
        display: block;
        position: relative;
    }

    & > .title_underline:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 4px;
        width: 50%; /* or 100px */
        background: var (--sq - orange);
        border-radius: 100px;
    }
`

const CardLayout = styled.div`
    display: flex;

    @media only screen and (max-width: 800px) {
        flex-direction: column;
    }

    @media only screen and (min-width: 800px) {
        & > .titleImage {
            height: 100%;
            width: 35%;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
        }
    }

    & > .titleImage {
        object-fit: cover;
    }
`

const Layout = styled.div`
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-flex: 1;
    /* position: relative; */
    //height: 800px;
    width: 100%;
    flex-shrink: 0;
`

const TestCardWrapper = styled.div`
    width: 100%;
    background: white;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
`

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '20px'
    },
    button: {
        borderRadius: '15px',
        padding: '5px 15px',
        minWidth: '150px',
        maxWidth: '200px'
    }
})

const SuccButton = styled(Button)`
    background: var(--sq-orange) !important;
    color: white !important;
`
