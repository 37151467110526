import React from 'react'
import SQLogo from '../Logo'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import ProfileComponent from '../../LogicalComponents/ProfileComponent'
import { getScreenWidth, START_DESKTOP_VERSION } from '../../../App'
import { RouteDefinition } from '../../../router/routes'
import { BookmarkBorder } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

const IconWithIndex = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 0.5em;
    color: var(--sq-orange);
    .icon-base {
        margin-top: 0.3em;
    }
    .index-count {
        position: absolute;
        top: 0;
        right: 0;
    }
    /* Farbänderung des Icon beim Hover über NavLink zu weiß*/
    .nav__link:hover & {
        color: white;
    }
`

function getTopSpace() {
    return getScreenWidth() < START_DESKTOP_VERSION ? '2em' : '0em'
}

const NavLinkWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    padding-top: ${getTopSpace};
    height: 100vh;
    overflow-y: auto;

    a {
        text-decoration: none;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 30px;
        color: #8da1b5;
        display: flex;
        align-items: center;
    }

    a:hover {
        background: var(--sq-orange);
        color: white !important;
    }

    .active__link {
        color: var(--sq-orange) !important;
        border-right: solid 5px var(--sq-orange);
    }
`

const LogoContainer = styled.div`
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 30px;
    margin-bottom: 10px;
`

function VerticalMenu({
    routes,
    changeMenuVisibility
}: {
    routes: RouteDefinition[]
    changeMenuVisibility: Function | undefined
    key: string
}) {
    const { t } = useTranslation()
    return (
        <NavLinkWrapper>
            <div>
                <LogoContainer>
                    <SQLogo />
                </LogoContainer>
                <div style={{ zIndex: '300' }}>
                    {routes.map(
                        (route: any) =>
                            !route.hidden && (
                                <NavLink
                                    key={route.id}
                                    exact
                                    activeClassName="active__link"
                                    to={route.path}
                                    className="nav__link"
                                    style={{ paddingBottom: '0.8em', paddingTop: '0.8em' }}
                                    onClick={() => {
                                        changeMenuVisibility && changeMenuVisibility()
                                    }}>
                                    {route.icon}
                                    <div style={{ marginRight: '10px' }}></div>
                                    {route.name}
                                    {route.newChapterCount ? (
                                        <IconWithIndex
                                            className="icon-with-index"
                                            title={`${route.newChapterCount} ${
                                                route.newChapterCount > 1
                                                    ? t('nav:new_chapter_plural')
                                                    : t('nav:new_chapter')
                                            }`}>
                                            <div
                                                className="icon-base"
                                                style={{ marginRight: '0.3em' }}>
                                                <BookmarkBorder />
                                            </div>
                                            <div
                                                className="index-count"
                                                style={{ fontSize: '0.8em' }}>
                                                {route.newChapterCount}
                                            </div>
                                        </IconWithIndex>
                                    ) : (
                                        <></>
                                    )}
                                </NavLink>
                            )
                    )}
                </div>
            </div>

            <div style={{ padding: '20px' }}>
                <ProfileComponent />
            </div>
        </NavLinkWrapper>
    )
}

export default VerticalMenu
