import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import React from 'react'

export function Buttons({ text }: { text: string | undefined }): JSX.Element {
    const classes = useStyles()
    return (
        <Button type="submit" fullWidth className={classes.submit}>
            {text}
        </Button>
    )
}

export function OrangeButtonLink({
    text,
    linkUrl
}: {
    text: string | undefined
    linkUrl: string | undefined
}): JSX.Element {
    const classes = useStyles()
    return (
        <a href={linkUrl} target="_blank" rel="noopener noreferrer">
            <Button type="submit" className={classes.submit}>
                {text}
            </Button>
        </a>
    )
}

const useStyles = makeStyles((theme) => ({
    submit: {
        margin: theme.spacing(3, 0, 2),
        padding: '10px',
        background: '#FF8D2E',
        color: 'white',
        '&:hover': {
            //you want this to be the same as the backgroundColor above
            backgroundColor: 'var(--sq-orange-hover)'
        }
    }
}))
