import { Typography } from '@material-ui/core'
import { PageTitleWrapper } from '../../Components/NonLogicalComponents/course/PageTitleWrapper'
import React from 'react'
import { t } from 'i18next'
import CourseVideo from '../../Components/NonLogicalComponents/course/CourseVideo'
import { CardsWrapper } from '../../Components/NonLogicalComponents/CardsWrapper'

// To find out how many videos exist for the selected language, the entries in the translation file are counted.
// As soon as no further video id is available, the returned string starts with 'videosfalse'. This is breaking the counting loop.
function checkEndOfVideoIdTranslation(numberOfVideos: number) {
    // get videoId and a substring of first 11 chars
    const videoIdInSpecificLanguage = t('installationVideos:videos:' + numberOfVideos)
    const videoIdInSpecificLanguageSubstring = videoIdInSpecificLanguage.substring(0, 11)
    //check whether no video id is available anymore (meaning video id contains videosfalse
    const reachedEndOfVideoIds = videoIdInSpecificLanguageSubstring === 'videosfalse'

    return reachedEndOfVideoIds
}

// Info: video ids are stored as objects in translation files as translation (switching videos) when changing the language is easier this way
// (otherwise additional logic for switching the videos when switching the language is required)

export default function InstallationVideoPage() {
    let numberOfVideos = 0

    while (!checkEndOfVideoIdTranslation(numberOfVideos)) {
        numberOfVideos += 1
    }

    return (
        <div>
            <PageTitleWrapper>
                <Typography variant="h4">{t('installationVideos:installation')}</Typography>
            </PageTitleWrapper>
            <CardsWrapper>
                {[...Array(numberOfVideos)].map((i, index) => {
                    const id = t('installationVideos:videos:' + index)
                    const thumbnailLink = t('installationVideos:thumbnails:' + index)

                    if (id === '') {
                        return <></>
                    }
                    return (
                        <div
                            style={{
                                width: '100%',
                                maxWidth: '500px',
                                alignItems: 'start',
                                marginRight: 'auto'
                            }}>
                            <CourseVideo
                                videoID={id}
                                autoplay={false}
                                thumbnailLink={thumbnailLink}
                            />
                        </div>
                    )
                })}
            </CardsWrapper>
        </div>
    )
}
