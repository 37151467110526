import { Button } from '@material-ui/core'
import styled from 'styled-components'

const StyledButton = styled(Button)`
    //position: absolute !important;
    bottom: 10px;
    right: 10px;
    background: var(--sq-orange) !important;
    color: white !important;
    @media (max-width: 600px) {
    }
`

export default StyledButton
