import { Button, CardActions, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Drawer from '../../Components/LogicalComponents/react-bottom-drawer'
import MenuIcon from '@material-ui/icons/Menu'
import { useParams } from 'react-router-dom'
import StyledButton from '../../Components/NonLogicalComponents/StyledButton'
import {
    Layout,
    LeftSection,
    RightSection
} from '../../Components/NonLogicalComponents/course/ActiveCourseLayout'
import CourseVideo from '../../Components/NonLogicalComponents/course/CourseVideo'
import { PlayListStep } from '../../Components/NonLogicalComponents/course/PlayListStep'
import { Transcript } from '../../Components/NonLogicalComponents/Transcript'
import { DrawerPlaylist } from '../../Components/NonLogicalComponents/course/DrawerPlaylist'
import { CourseActiveQuizCard } from './ChapterQuizCard'
import { TopNav } from '../../Components/NonLogicalComponents/layout/TopNav'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import {
    GetDetailedLectionQuery,
    useGetDetailedLectionQuery,
    useGetUserDetailsQuery,
    useInsertLogEventMutation
} from '../../generated/graphql'
import { HomePath } from '../../router/RouteConstants'
import CourseRewardModal from '../../Components/NonLogicalComponents/course/CourseRewardModal'
import CustomSanityBlock from '../../Components/NonLogicalComponents/CustomSanityBlock'
import { toast } from 'react-toastify'
import { useCustomTrans } from '../../i18n/UseCustomTrans'
import {
    CONTACT_POINT_ID_TO_SECTION_NAMES_MAPPING,
    SECTION_NAMES_TO_CONTACT_POINT_ID_MAPPING
} from '../../router/routes'
import { SectionNames } from '../../Components/NonLogicalComponents/course/SectionComponent'

{
    /*
The parameter data contains (see GetDetailedLection Query) the first chapter that has not been completed. (Query is sorting chapter according index and returning an array of chapters
that are not completed.
The function selects the first chapter of this array and sets its index as the index of the chapter that should be displayed as active chapter.
If no unfinished chapter exists, the hook indicating whether a lection is completed is set to true.
*/
}
function setFirstActiveChapterIndex({
    data,
    setActiveChapterIndex,
    setLectionCompleted
}: {
    data: GetDetailedLectionQuery | undefined
    setActiveChapterIndex: Function
    setLectionCompleted: Function
}) {
    const firstUnfinishedChapterIndex = data?.firstOpenChapter?.[0]?.chapter?.index

    if (firstUnfinishedChapterIndex !== null) {
        setActiveChapterIndex(firstUnfinishedChapterIndex ?? 0)
    } else {
        setLectionCompleted(true)
    }
}

function CourseActivePage() {
    const history = useHistory()
    const [insertLogEvent] = useInsertLogEventMutation()
    const [activeChapterIndex, setActiveChapterIndex] = useState(0)
    const [lectionCompleted, setLectionCompleted] = useState(false)
    const [drawerVisible, setdrawerVisible] = useState(false)
    const [testActive, setTestActive] = useState(false)
    const [openSuccModal, setOpenSuccModal] = useState(false)
    const [gainedExp, setGainedExp] = useState(0)
    const { t } = useTranslation('courses')
    const tr = useCustomTrans()

    const openDrawer = () => {
        setdrawerVisible(!drawerVisible)
    }
    const closeDrawer = () => {
        setdrawerVisible(false)
    }

    // @ts-ignore
    let { id } = useParams()
    let idAsInt = parseInt(id)

    if (idAsInt === undefined || idAsInt === null || isNaN(idAsInt)) {
        history.push(HomePath)
    }

    const { data, client, refetch } = useGetDetailedLectionQuery({
        variables: { id: parseInt(id) },
        fetchPolicy: 'network-only'
    })

    useEffect(() => {
        setFirstActiveChapterIndex({ data, setActiveChapterIndex, setLectionCompleted })
    }, [data])

    const contactpointId = data?.lection?.contactPointId ?? -1

    // for product lection all chapters should be accessible at once
    // if displayed lection is in "Product-ContactPoint" then done chapters is set to 0
    // in order to make sure that every locked check in the following works
    let allChaptersUnlocked = false

    if (SECTION_NAMES_TO_CONTACT_POINT_ID_MAPPING[SectionNames.PRODUCTS].includes(contactpointId)) {
        allChaptersUnlocked = true
    }

    const { data: userInfo } = useGetUserDetailsQuery()
    const userId = userInfo?.user_details[0].user?.id
    const vendorId = userInfo?.user_details[0].user?.organisation.id

    const lection = data?.lection
    const activeChapter = lection?.chapters[activeChapterIndex]

    const handleFinishedTest = (gainedExpPoints: number) => {
        setTestActive(false)
        // find any chapter that is not finished yet (menaing chapter progress is null) and is not the currently active chapter
        const checkUnfinishedChapterLeft = data?.lection?.chapters.some((chapter) => {
            return chapter.progress === null && chapter.id !== activeChapter?.id
        })
        if (!checkUnfinishedChapterLeft) {
            setGainedExp(gainedExpPoints)
            setOpenSuccModal(true)
        } else {
            if (gainedExpPoints > 0) {
                toast.success(t('toast_quiz_answer_exp', { gainedExpPoints }))
            } else {
                toast.info(t('toast_quiz_answer_already_done'), {})
            }
            refetch()
            setFirstActiveChapterIndex({ data, setActiveChapterIndex, setLectionCompleted })
        }
    }

    useEffect(() => {
        if (
            activeChapter?.id !== undefined &&
            lection?.id !== undefined &&
            vendorId !== undefined &&
            userId !== undefined &&
            lectionCompleted !== undefined
        ) {
            const response = insertLogEvent({
                variables: {
                    createdAt: new Date().toISOString(),
                    parameters: {
                        chapterId: activeChapter?.id,
                        lectionId: lection?.id,
                        contactPointId: lection.contactPointId
                    },
                    type: lectionCompleted ? 'CompletedLectionViewed' : 'UncompletedLectionViewed',
                    userId: userId,
                    vendorId: vendorId,
                    description: lection?.title ?? ''
                }
            })
        }
    }, [activeChapter?.id, lection?.id, vendorId, userId, lectionCompleted])

    const redirectLectionId =
        CONTACT_POINT_ID_TO_SECTION_NAMES_MAPPING[data?.lection?.contactPointId ?? -1] ?? 'home'
    const redirectContactpointName = `/${redirectLectionId}`

    return (
        <div>
            <TopNav>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={() => history.push(redirectContactpointName)}>
                        <ArrowBackIosIcon /> {t('courses:overview')}
                    </Button>
                    <div style={{ right: '12px' }} className="disable_on_desktop">
                        <Button onClick={openDrawer}>
                            <MenuIcon />
                            {t('chapters')}
                        </Button>
                    </div>
                </div>
            </TopNav>
            <br />

            <Layout>
                <LeftSection className="disable_on_small">
                    <Typography variant="h5" component="h3">
                        {tr(lection?.title_i18n)}
                    </Typography>
                    <br />
                    <br />

                    {lection?.chapters?.map((chapter, currentChapterIndex: number) => {
                        const chapterDone = chapter.progress === null ? false : true

                        let locked
                        // if Product-Section no chapter is locked
                        if (allChaptersUnlocked) {
                            locked = false
                        } else {
                            const completedChapterCount = data?.solvedChapters.aggregate?.count ?? 0
                            locked = currentChapterIndex > completedChapterCount
                        }
                        // TODO Check if chapter is done
                        return (
                            <PlayListStep
                                active={currentChapterIndex === activeChapterIndex}
                                locked={locked}
                                onClicked={() => setActiveChapterIndex(currentChapterIndex)}
                                done={chapterDone}
                                key={currentChapterIndex}
                                minutes={chapter?.required_minutes}
                                title={tr(chapter?.title_i18n)}
                            />
                        )
                    })}
                </LeftSection>

                <RightSection>
                    <CourseVideo videoID={tr(activeChapter?.video_i18n)} autoplay={false} />
                    {testActive && lection && activeChapter ? (
                        <CourseActiveQuizCard
                            onCanceled={() => setTestActive(false)}
                            lectionId={lection.id}
                            chapterId={activeChapter.id}
                            quiz={activeChapter.quiz}
                            onFinished={(gainedExpPoints) => handleFinishedTest(gainedExpPoints)}
                        />
                    ) : (
                        <Transcript>
                            <Typography variant="h4">{tr(activeChapter?.title_i18n)}</Typography>
                            <br />
                            {activeChapter?.text_raw && (
                                <CustomSanityBlock blocks={tr(activeChapter.text_i18n)} />
                            )}
                            <br />
                            <br />
                            <CardActions
                                style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div />
                                {data && activeChapter?.quiz ? (
                                    <StyledButton onClick={() => setTestActive(true)}>
                                        {t('courses:startTest')}
                                    </StyledButton>
                                ) : (
                                    <StyledButton
                                        onClick={() => setActiveChapterIndex(activeChapterIndex)}>
                                        {t('courses:nextChapter')}
                                    </StyledButton>
                                )}
                            </CardActions>
                        </Transcript>
                    )}
                </RightSection>

                <Drawer isVisible={drawerVisible} onClose={closeDrawer}>
                    <DrawerPlaylist>
                        <Typography variant="h5" component="h3">
                            {lection?.title}
                        </Typography>
                        <br />
                        <br />
                        {lection?.chapters?.map((chapter: any, currentChapterIndex: number) => {
                            const chapterDone = chapter?.progress === null ? false : true
                            return (
                                <PlayListStep
                                    active={currentChapterIndex === activeChapterIndex}
                                    locked={chapter.progress === null}
                                    onClicked={() => setActiveChapterIndex(currentChapterIndex)}
                                    done={chapterDone}
                                    key={currentChapterIndex}
                                    minutes={chapter?.required_minutes}
                                    title={chapter?.title}
                                />
                            )
                        })}
                    </DrawerPlaylist>
                </Drawer>
                <CourseRewardModal
                    open={openSuccModal}
                    gainedExp={gainedExp}
                    onClosed={async () => {
                        await client?.resetStore() // Reset the Progress
                        history.push(HomePath)
                    }}
                />
            </Layout>
        </div>
    )
}

export default CourseActivePage
