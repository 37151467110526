const __createBinding =
    (this && this.__createBinding) ||
    (Object.create
        ? function (o, m, k, k2) {
              if (k2 === undefined) k2 = k
              Object.defineProperty(o, k2, {
                  enumerable: true,
                  get: function () {
                      return m[k]
                  }
              })
          }
        : function (o, m, k, k2) {
              if (k2 === undefined) k2 = k
              o[k2] = m[k]
          })
const __setModuleDefault =
    (this && this.__setModuleDefault) ||
    (Object.create
        ? function (o, v) {
              Object.defineProperty(o, 'default', { enumerable: true, value: v })
          }
        : function (o, v) {
              o.default = v
          })
const __importStar =
    (this && this.__importStar) ||
    function (mod) {
        if (mod && mod.__esModule) return mod
        const result = {}
        if (mod != null) {
            for (const k in mod) {
                if (k !== 'default' && Object.prototype.hasOwnProperty.call(mod, k)) {
                    __createBinding(result, mod, k)
                }
            }
        }
        __setModuleDefault(result, mod)
        return result
    }
Object.defineProperty(exports, '__esModule', { value: true })
const React = __importStar(require('react'))
const body_scroll_lock_1 = require('body-scroll-lock')
function usePreventScroll(enabled, contentWrapperClass) {
    React.useLayoutEffect(
        function () {
            if (typeof document === 'undefined' || !enabled) {
                return
            }
            const scrollableElement = document.querySelector('.' + contentWrapperClass)
            body_scroll_lock_1.disableBodyScroll(scrollableElement)
            console.log('Disable body scrol')
            return function () {
                body_scroll_lock_1.enableBodyScroll(scrollableElement)
            }
        },
        [enabled, contentWrapperClass]
    )
}
exports.default = usePreventScroll
