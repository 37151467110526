export default {
    new: 'Neu',
    submit: 'Absenden',
    cancel: 'Abbrechen',
    logout: 'Ausloggen',
    login: 'Log in',
    course: 'Kurs',
    lection: 'Lektion',
    chapter: 'Kapitel',
    back: 'Zurück',
    next: 'Weiter',
    of: 'von',
    finish: 'Fertigstellen',
    error_loading_content: 'Fehler beim Laden des Inhalts',
    hand: 'Hand',
    foot: 'Fuß',
    pelvis: 'Becken',
    profiler: 'Profiler',
    intro: 'Intro',
    products: 'Produkte',
    getting_started: 'Leg direkt los:',
    continue_directly: 'Knüpfe an deinem letzten Fortschritt an:',
    continue_with_new_content: 'Lege bei den neuen Inhalten los:',
    contact_point_section_caption: 'Mache bei einer Kontaktstelle weiter:',
    welcome_back: 'Willkommen zurück{{username}}!'
}
