import React, { useEffect, useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import styled from 'styled-components'
import { IconButton, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { removeUser } from '../../Utils/setUser'
import { useTranslation } from 'react-i18next'
import { useGetUserDetailsQuery, useSubUserDetailsSubscription } from '../../generated/graphql'
import { setUser } from '@sentry/react'
import posthog from 'posthog-js'
import ChangePasswordModal from './login/ChangePasswordModal'
import { useCustomTrans } from '../../i18n/UseCustomTrans'
import { Skeleton } from '@material-ui/lab'
import { SmallLanguageSwitcher } from '../../Views/account/SmallLanguageSwitcher'
import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import { MdSportsScore } from 'react-icons/md'

export const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 10,
            borderRadius: 5
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
        },
        bar: {
            borderRadius: 5,
            transition: 'none',
            backgroundColor: '#ef7103'
        }
    })
)(LinearProgress)

const ProfileNameWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const Placeholder = styled.div`
    width: 8px;
`
const CustomAvatar = styled(Avatar)`
    margin-right: 10px;
    position: relative;
    background: white;
    border: 1px solid var(--sq-orange);
`

const TruncatedText = styled.p`
    white-space: nowrap;
    width: 100%; /* IE6 needs any width */
    max-width: 180px;
    overflow: hidden; /* "overflow" value must be different from  visible"*/
    -o-text-overflow: ellipsis; /* Opera < 11*/
    text-overflow: ellipsis; /* IE, Safari (WebKit), Opera >= 11, FF > 6 */
`

function ProfileComponent() {
    const { data, loading } = useGetUserDetailsQuery()
    const subscription = useSubUserDetailsSubscription()

    const { t, i18n } = useTranslation('profile')

    const details = data?.user_details[0]
    const user = details?.user
    const userName = user?.name
    const userAvatar = user?.avatar_url ?? ''
    const email = user?.email_address
    const shopName = user?.organisation?.shop_name
    const vendorId = user?.organisation?.id
    const [dialogOpen, setDialogOpen] = useState(false)
    const [forcePasswordReset, setForcePasswordReset] = useState(false)
    const tr = useCustomTrans()

    useEffect(() => {
        if (!data) return
        const userInfo = {
            id: user?.id,
            username: userName ?? '',
            email: email ?? '',
            shopName: shopName,
            vendorId: vendorId
        }
        // set Sentry
        setUser(userInfo)

        posthog.identify(user?.id, userInfo)
        if (user?.language) {
            console.log('Setting language to ' + user?.language + ' from the backend')
            i18n.changeLanguage(user?.language)
        }

        // Force the user to set a new password
        if (user?.is_temporary_password) {
            setForcePasswordReset(true)
            setDialogOpen(true)
        }
    }, [data])

    // load subscription data
    const subUser = subscription.data?.user_details[0]
    const level = subUser?.level?.title_i18n
    const exp = subUser?.exp_points ?? 0

    const findNextLevelPoints = () => {
        const points = data?.content_levels ?? [] // Are sorted ascending by points
        for (const level of points) {
            if (level.required_points > exp) {
                return level.required_points ?? -1
            }
        }
        return data?.content_levels?.pop()?.required_points ?? -1
    }

    const getLevelProgress = () => {
        return (exp * 100) / findNextLevelPoints()
    }

    const levelProgress = getLevelProgress()
    const nextLevelPoints = findNextLevelPoints()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleLogout = () => {
        try {
            posthog.reset(true)
        } catch (e) {
            console.error(e)
        }

        removeUser()
        handleClose()
        window.location.reload()
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const finished =
        data?.progressChapterOverview.length === 0 &&
        data?.progressContactpointOverview.length === 0

    return loading ? (
        <Skeleton height="230px" />
    ) : (
        <div>
            <ProfileNameWrapper>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CustomAvatar alt="Remy Sharp" src={userAvatar} />

                    <div>
                        <Typography variant="h5" component="h3">
                            {userName}
                        </Typography>
                        <Typography variant="subtitle2" component="h3">
                            <TruncatedText>{shopName}</TruncatedText>
                        </Typography>
                    </div>
                </div>

                <Placeholder />
                <IconButton
                    color="primary"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}>
                    <ExpandMoreIcon color="primary" />
                </IconButton>

                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}>
                    <MenuItem onClick={() => handleLogout()}>{t('profile:logout')}</MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleClose()
                            setDialogOpen(true)
                        }}>
                        {t('profile:change_password')}
                    </MenuItem>
                </Menu>
            </ProfileNameWrapper>

            {/* As soon as the user has finished the entire content, a finish
            The tooltip is visible if a user finished the entire content a finishing flag is displayed
            and if the user hovers over the section indicating the points and progress bar,
            the info banner that further points can be achieved as soon as new content is available becomes visible.
            */}
            <Tooltip
                title={
                    finished ? (
                        <h2 style={{ color: 'white', lineHeight: '1.5em' }}>{t('points_info')}</h2>
                    ) : (
                        ''
                    )
                }>
                {/* points progress indication */}
                <div
                    style={{
                        marginTop: '10px',
                        marginBottom: '30px',
                        cursor: finished ? 'pointer' : ''
                    }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p style={{ color: 'gray', marginBottom: '5px' }}>{tr(level)}</p>
                        <div style={{ display: 'flex' }}>
                            <p style={{ color: 'gray', fontSize: '8x' }}>
                                {exp} / {nextLevelPoints} P
                            </p>
                            {finished ? (
                                <MdSportsScore
                                    style={{
                                        color: 'grey',
                                        marginLeft: '0.2em',
                                        fontSize: '1.5em'
                                    }}
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                    <BorderLinearProgress variant="determinate" value={levelProgress} />
                </div>
            </Tooltip>
            <ChangePasswordModal
                force={forcePasswordReset}
                open={dialogOpen}
                handleClose={() => setDialogOpen(false)}
            />

            <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                <SmallLanguageSwitcher isLoggedIn={true} />
            </div>
        </div>
    )
}

export default ProfileComponent
