export default {
    rememberMe: 'Remember Me',
    logout: 'Logout',
    title: 'B2B Login',
    username: 'Client Number',
    password: 'B2B Password',
    loginBtn: 'Log in',
    usePW: 'Use Password',
    note: 'Note',
    welcome: 'Welcome!',
    error_vendor: 'The access data is not valid. Please try again or reset your password.',
    label_b2b_login: 'B2B Login',
    label_profile_login: 'Profile Login',
    profile_email: 'E-mail address',
    profile_password: 'Password',
    register_text: 'You have no account yet?',
    error_email_exists: 'A profile with this email is already registered. Please use another email or sign in.',
    error_vendor_id_not_found: 'The client number is not valid or blocked. Please contact the support.',
    create_new_profile: 'Create new profile',
    info_customer_numbers: 'Please note the current conversion of customer numbers. The new version has "00" at the end.',
    reset_password_question: 'Forgot Password?',
    reset_password_btn: 'Request new password',
    reset_password_title: 'Request new password',
    reset_password_succ: 'If the email address was correct, you will find a new password in your mailbox.'
}
