import React from 'react'
import styled from 'styled-components'

const LeftSection = styled.div`
    min-width: 300px;
    max-width: 300px;
    width: 100%;
    top: 0px;
    bottom: 0;
    position: sticky;
    left: 0;
    height: 100vh;
    background: white;
    border-right: solid 0px var(--border-color);
`


export const DesktopLayout = (props: any) => {
    return (
            <LeftSection>{props.drawer?.map((item: any) => item)}</LeftSection>
    )
}
