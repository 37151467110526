import { DescriptionCardType } from '../DescriptionCardComponents/FullWidthDescriptionCard'
import { ActiveCoursePath } from '../../../router/RouteConstants'
import { LectionInfoFragment } from '../../../generated/graphql'

export function calculateTotalTimeOfContent(content: LectionInfoFragment[]): number {
    if (content[0] === undefined) {
        return -1
    }

    // only continue with the chapters that are not finished (according to the completedCount and their index)
    const chapters = content.flatMap(
        (it) =>
            // only include the chapters that are not completed yet to calculate the total time
            it.lection?.chapters.slice(it.completedCount, it.totalCount) ?? []
    )

    // Use a type guard to narrow down the type and filter out null or undefined
    const durations = chapters
        .filter((it): it is { requiredMinutes: number } => it != null)
        .map((it) => it.requiredMinutes)

    // Calculate sum of all required minutes
    const initialValue = 0
    return durations.reduce(
        (accumulator: number, currentValue: number) =>
            // for each chapter 2 minutes are added to address the estimated time a user needs for answering the chapter quiz question
            accumulator + 2 + (currentValue ?? 0),
        initialValue
    )
}

export function toAggregatedContactpointInfo(
    cpAggregationInfo: LectionInfoFragment[] | undefined,
    title: string
): [DescriptionCardType | null, LectionInfoFragment | undefined] {
    if (!cpAggregationInfo) {
        return [null, undefined]
    }

    // Note: img url of contact point is the same for all lections of the contact point
    const contactPointImgUrl = cpAggregationInfo?.[0]?.contactpoint?.imgUrl ?? ''

    const totalCount = cpAggregationInfo.length
    const completedCount = cpAggregationInfo.filter(
        (lection) => lection.completedCount >= lection.totalCount
    ).length

    // Set redirectUrl to first not completed lection or to last lection if all lections are completed
    let linkedLectionId: number
    const firstNotFinishedLection = cpAggregationInfo.find(
        (lection) => lection.completedCount < lection.totalCount
    )

    if (firstNotFinishedLection) {
        linkedLectionId = firstNotFinishedLection?.lectionId as number
    } else {
        if (cpAggregationInfo.length > 0) {
            const lastLection = cpAggregationInfo[cpAggregationInfo.length - 1]
            linkedLectionId = lastLection?.lectionId as number
        } else {
            linkedLectionId = -1
        }
    }

    const redirectUrl = `${ActiveCoursePath}/${linkedLectionId}`

    // Calculate total duration of all chapters of the contact point and add 10 minutes for each final quiz
    let totalDuration = calculateTotalTimeOfContent(cpAggregationInfo)

    // Map each object to its finalQuizId if lastCompletedAt exists
    const finalQuizIds = cpAggregationInfo
        .map((item) =>
            item.contactpoint &&
            item?.contactpoint?.finalQuizId &&
            item.contactpoint.progress === null
                ? item.contactpoint.finalQuizId
                : null
        )
        .filter((value) => value !== null)

    // Convert the finalQuizIds array to a Set to remove duplicates
    const numberOfFinalQuizzes = new Set(finalQuizIds).size

    // if isLocked undefined check whether lection is finished (-> set isLocked on false) otherwise lock it
    const firstNotFinishedLectionLocked =
        firstNotFinishedLection?.isLocked ?? (completedCount >= totalCount ? false : true)

    totalDuration += numberOfFinalQuizzes * 10

    return [
        {
            totalCount: totalCount,
            completedCount: completedCount,
            totalMinRequired: totalDuration,
            redirectUrl: redirectUrl,
            isLocked: firstNotFinishedLectionLocked,
            imgUrl: contactPointImgUrl,
            title: title,
            isCompleted: true,
            newChapterCount: undefined
        },
        firstNotFinishedLection
    ]
}

// transforms the structure of the relevant lection data (fetched from backend)
// to a simpler "universal" format that is used by several components
export function uniformLection(
    lectionInfo: any,
    translatedTitle: string,
    newChapterCount?: number
): DescriptionCardType {
    return {
        isCompleted: lectionInfo?.isCompleted ?? false,
        isLocked: lectionInfo?.isLocked,
        redirectUrl: `${ActiveCoursePath}/${lectionInfo?.lectionId}`,
        totalCount: lectionInfo?.totalCount ?? 0,
        completedCount: lectionInfo?.completedCount ?? 0,
        imgUrl: lectionInfo?.lection?.imgUrl,
        title: translatedTitle,
        totalMinRequired: calculateTotalTimeOfContent([lectionInfo]),
        newChapterCount: newChapterCount
    }
}
