import React, { useEffect, useState } from 'react'
import {
    QuestionFragment,
    useEvaluateQuizMutation,
    useGetDetailedQuizQuery
} from '../../generated/graphql'
import { Button, Card, CardActions, CardContent, makeStyles, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { useApolloClient } from '@apollo/client'
import succesGif from '../../assets/trophy.gif'
import {
    EvaluationCheckboxes,
    EvaluationQuestion
} from '../../Components/LogicalComponents/EvaluationCheckboxes'
import Loading from '../../Components/NonLogicalComponents/layout/Loading'
import { useTranslation } from 'react-i18next'
import InfoIcon from '@material-ui/icons/Info'
import { useCustomTrans } from '../../i18n/UseCustomTrans'

const notSuccPng =
    'https://img2.pngio.com/black-x-png-picture-1813528-black-x-png-black-x-png-288_300.png'

function getQuestionMapping(resultMap: Map<string, boolean>, questions: [QuestionFragment]) {
    return questions?.map((it) => ({
        title: it?.title_i18n,
        correct: resultMap.get(it?.id.toString() ?? ''),
        hint: it?.hint_i18n
    }))
}

const GamificationCounter = styled.p`
    color: var(--sq-orange);
    font-size: 30px;
    font-weight: bold;
`

export function QuizResultCard({ sessionID, onRetry, onFinished, quizID }: any) {
    const classes = useStyles()
    const { t } = useTranslation('finalquiz')
    const tr = useCustomTrans()
    const [evaluated, setEvaluated] = useState(false)
    const [evalText, setEvalText] = useState('')
    const [quizResult, setQuizResult] = useState({
        passed: false,
        correctAnswers: 0,
        totalAnswers: 0,
        gainedExp: 0
    })
    const [questionResults, setQuestionResults]: [EvaluationQuestion[], any] = useState([])
    const client = useApolloClient()
    const quiz = useGetDetailedQuizQuery({ variables: { id: quizID } })

    const [evaluate, { loading, called }] = useEvaluateQuizMutation()

    useEffect(() => {
        !evaluated && evaluateQuiz()
    }, [])

    const evaluateQuiz = async () => {
        try {
            const { data, errors } = await evaluate({ variables: { session_id: sessionID } })
            if (errors) {
                console.error('Error on evaluating quiz ', errors)
                return
            }
            const evaluationResponse = data?.evaluateQuiz

            const successText = t('quiz_passed')
            const failText = t('quiz_failed')

            const evalText = evaluationResponse!.passed ? successText : failText

            setEvaluated(true)
            setQuizResult({
                correctAnswers: evaluationResponse!.correctCount,
                totalAnswers: evaluationResponse!.totalCount,
                passed: evaluationResponse!.passed,
                gainedExp: evaluationResponse!.expPoints
            })

            const questions = getQuestionMapping(
                new Map<string, boolean>(Object.entries(data?.evaluateQuiz?.resultMap)),
                quiz?.data?.quiz?.questions as any
            )
            setQuestionResults(questions)

            setEvalText(evalText)
            await client?.resetStore() // Clear Cache for the Overview page
        } catch (e) {
            console.log('cannot fetch eval', e)
        }
    }

    return (
        <Layout>
            <TestCardWrapper>
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Typography variant="h4">{t('evaluation')}</Typography>
                        {loading && <Loading />}
                        <br />
                        <br />
                        {evaluated && (
                            <img
                                src={quizResult.passed ? succesGif : notSuccPng}
                                style={{ width: '50%', maxWidth: '200px' }}
                                alt=""
                            />
                        )}
                        {quizResult.passed && (
                            <div>
                                <GamificationCounter>+{quizResult.gainedExp} P</GamificationCounter>
                                {quizResult.gainedExp === 0 && (
                                    <p style={{ fontWeight: 'lighter' }}>
                                        <InfoIcon /> {t('finalquiz:noPoints')}
                                    </p>
                                )}
                            </div>
                        )}
                        <br />
                        <EvaluationCheckboxes questions={questionResults} />
                        <br />
                        <br />
                        <Typography variant="h5">{evalText}</Typography>
                        <br />
                    </CardContent>
                    <CardActions className={classes.buttons}>
                        {quizResult.passed ? (
                            <OrangeButton
                                className={classes.button}
                                onClick={() => onFinished()}
                                size="small">
                                {t('base:finish')}
                            </OrangeButton>
                        ) : (
                            <OrangeButton
                                className={classes.button}
                                onClick={() => onRetry()}
                                size="small">
                                {t('repeat_test')}
                            </OrangeButton>
                        )}
                    </CardActions>
                </Card>
            </TestCardWrapper>
        </Layout>
    )
}

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '20px',
        backgroundColor: 'rgb(247,247,247)',
        padding: '20px'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)'
    },
    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    },
    buttons: {
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        borderRadius: '20px',
        padding: '10px 15px'
    }
})

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 20px !important;
    text-align: center;
`

const TestCardWrapper = styled.div`
    width: 100%;
    background: white;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
`

const OrangeButton = styled(Button)`
    background: var(--sq-orange) !important;
    color: white !important;
`
