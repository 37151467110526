export default {
    overview: 'Cursusoverzicht',
    startTest: 'Start test',
    nextChapter: 'Volgende hoofdstuk',
    allcourses: 'Cursusoverzicht',
    completed: 'Voltooid',
    ongoing: 'Nieuw',
    no_courses: 'Sorry, er zijn geen lessen meer beschikbaar.',
    nothinghere_completed: 'Nog geen cursussen afgerond. Begin met een nieuwe cursus!',
    nothinghere_available:
        'Er zijn geen cursussen meer beschikbaar. Kijk uit naar de volgende inhoudsupdate!',
    quiz_wrong: 'Helaas was het antwoord fout.',
    quiz_right: 'Het antwoord was juist!',
    quiz_submit: 'Verzenden',
    quiz_cancel: 'Annuleren',
    title_quiz: 'Kwestie van begrip',
    tooltip_locked:
        'Deze cursus kan nog niet worden gestart, omdat nog niet aan alle voorwaarden is voldaan.',
    locked: 'Nog niet ontgrendeld',
    toast_quiz_answer_wrong: 'Helaas, dit antwoord was fout!',
    toast_quiz_answer_exp: '\uD83C\uDFC6 Heel goed! +{{gainedExpPoints}} Ervaringspunten',
    toast_quiz_answer_already_done: 'Je hebt de vraag al een keer goed beantwoord!',
    chapters: 'Hoofdstuk',
    requirements: 'Vereisten',
    continue: 'Doorgaan',
    successfully_completed: 'Succesvol afgerond',
    minutes: 'Minuten',
    new: 'Nieuw',
    new_plural: 'Nieuwe',
    get_started: 'Aan de slag'
}
