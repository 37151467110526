import { useCustomTrans } from '../../../i18n/UseCustomTrans'
import { Typography } from '@material-ui/core'
import HalfWidthDescriptionCard from '../DescriptionCardComponents/HalfWidthDescriptionCard'
import { InfoComponentSubsection } from '../DescriptionCardComponents/DescriptionCardElements/InfoComponent'
import React from 'react'
import styled from 'styled-components'
import { uniformLection } from './DataMapper'

const HalfWidthLayout = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    @media (max-width: 750px) {
        width: 100%;
    }
`

export default function FocusComponents({
    title,
    lectionInfo
}: {
    title: string
    lectionInfo: any
}) {
    const tr = useCustomTrans()
    const uniformedLection = uniformLection(lectionInfo, tr(lectionInfo?.lection?.titleI18n))

    return (
        <HalfWidthLayout>
            <Typography variant="h5"> {title} </Typography>
            <HalfWidthDescriptionCard
                itemDetails={uniformedLection}
                infoComponentSubsectionName={InfoComponentSubsection.CHAPTER}
            />
        </HalfWidthLayout>
    )
}
