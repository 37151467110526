import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './index.css'
import { ThemeProvider } from 'styled-components'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { ApolloProvider } from '@apollo/client'
import { client, theme } from './Apollo'
import { createTheme, MuiThemeProvider } from '@material-ui/core'
import { purple } from '@material-ui/core/colors'
import posthog from 'posthog-js'
import i18n from './i18n/i18n'
import { I18nextProvider } from 'react-i18next'

const materialTheme = createTheme({
    palette: {
        primary: {
            main: '#ff8d2e'
        },
        secondary: purple
    }
})

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://766c693686004ded9fdf1b6eacbc1c91@o82329.ingest.sentry.io/5599770',
        autoSessionTracking: true,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 10.0,
        release: 'iqlab@' + process.env.npm_package_version
    })
    posthog.init('fC2pUuqgr1IjIx9QLLZ24sz_V_MTxjzZ2OVVFC8Ca4Q', {
        api_host: 'https://posthog.die-ergonomie.de'
    })
} 
// loading component for suspense fallback
const Loader = () => <div className="App" />

ReactDOM.render(
    <React.StrictMode>
        <Sentry.ErrorBoundary>
            <Suspense fallback={Loader}>
                <MuiThemeProvider theme={materialTheme}>
                    <ThemeProvider theme={theme}>
                        <ApolloProvider client={client}>
                            <I18nextProvider i18n={i18n}>
                                <App />
                            </I18nextProvider>
                        </ApolloProvider>
                    </ThemeProvider>
                </MuiThemeProvider>
            </Suspense>
        </Sentry.ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root')
)
