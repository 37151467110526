export default {
    installation: 'Montage-Tipps',
    videos: {
        0: 'bd3d065511437cf8875b9ad9941602aa', // SQlab_Einstelltipps_Sattel
        1: 'eeeda7999833ce5eab198cb4ee670408', // Montage_Anbindung_613R_DE
        2: '44601df2105fcd4557d9ca3e4dd4d6d5', // 712_Montage_DE_YouTube
        3: '8e338967bb91e1c4fbca89dc7fd38837', // 711R_Montageanleitung_DE_YouTube
        4: '257dadfd7ed2e4dac1b7aae3487cb92f', // Bikefitting_Lenker
        5: '0621fb74c30e31b4d021056ce626669e', // Bikefitting_Pedale
        6: 'b11e8ae59ba42067e1d718bfdc581e40', // Griffmontage
        7: '670941918b3e33e7e622e5c301ab39df', // Pflegehinweise
        8: '4850f1f2cfc9ea0c0d3e1c6835af4121', // Griffmontage 710 & 702
    },
    thumbnails: {
        0: 'https://sqlab-public.s3.eu-central-1.wasabisys.com/video_thumbnails/iqlab/SQlab.youtube.Thumbnail.Einstelltipps.Sattel.0521.jpg', // SQlab_Einstelltipps_Sattel
        1: 'https://sqlab-public.s3.eu-central-1.wasabisys.com/video_thumbnails/iqlab/SQlab.youtube.Thumbnail.613r.integrated.bottle.holder.0421.jpg', // Montage_Anbindung_613R_DE
        2: 'https://sqlab-public.s3.eu-central-1.wasabisys.com/video_thumbnails/iqlab/SQlab.youtube.Thumbnail.Montageanleitungen.0920.jpg', // 712_Montage_DE_YouTube
        3: 'https://sqlab-public.s3.eu-central-1.wasabisys.com/video_thumbnails/iqlab/SQlab.youtube.Thumbnail.Montageanleitungen.09203.jpg', // 711R_Montageanleitung_DE_YouTube
        4: 'https://sqlab-public.s3.eu-central-1.wasabisys.com/video_thumbnails/iqlab/SQlab.youtube.Thumbnail.bikefitting.frauen.07212.jpg', // Bikefitting_Lenker
        5: 'https://sqlab-public.s3.eu-central-1.wasabisys.com/video_thumbnails/iqlab/SQlab.youtube.Thumbnail.bikefitting.frauen.0721.jpg', // Bikefitting_Pedale
        6: 'https://sqlab-public.s3.eu-central-1.wasabisys.com/video_thumbnails/iqlab/SQlab.youtube.Thumbnail.Griffe.Steinernut.08202.jpg', // Griffmontage
        7: 'https://sqlab-public.s3.eu-central-1.wasabisys.com/video_thumbnails/iqlab/SQlab.youtube.Thumbnail.pflegetipps.sattel.1022.jpg', // Pflegehinweise
        8: 'https://sqlab-public.s3.eu-central-1.wasabisys.com/video_thumbnails/iqlab/SQlab.youtube.Thumbnail.Griffe.Steinernut.0820.jpg', // Griffmontage 710 & 702
    }
}
