import { Typography } from '@material-ui/core'
import { PageTitleWrapper } from '../course/PageTitleWrapper'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { theme } from '../../../Apollo'
import {
    Exact,
    GetHomePageInfoQuery,
    GetSingleLectionForHomepageQuery,
    GetUnfinishedChapterIdsQuery,
    LectionInfoFragment,
    useGetHomePageInfoQuery,
    useGetSingleLectionForHomepageLazyQuery,
    useGetUnfinishedChapterIdsLazyQuery
} from '../../../generated/graphql'
import { InfoComponentSubsection } from '../DescriptionCardComponents/DescriptionCardElements/InfoComponent'
import ThirdWidthDescriptionCard from '../DescriptionCardComponents/ThirdWidthDescriptionCard'
import styled from 'styled-components'
import {
    CONTACT_POINT_ID_TO_SECTION_NAMES_MAPPING,
    SECTION_NAMES_TO_CONTACT_POINT_ID_MAPPING
} from '../../../router/routes'
import { SectionNames } from '../course/SectionComponent'
import FocusComponents from './FocusComponent'
import { toAggregatedContactpointInfo } from './DataMapper'
import { DescriptionCardType } from '../DescriptionCardComponents/FullWidthDescriptionCard'
import { LazyQueryExecFunction } from '@apollo/client'

const ThirdWidthLayout = styled.div`
    width: 33%;
    @media (max-width: 750px) {
        width: 100%;
    }
`

function HomeContent({
    firstNameToDisplay,
    lectionToContinue,
    lastVisitedTitle,
    allCpAggregateInfo,
    newLection
}: {
    firstNameToDisplay: string
    lectionToContinue: LectionInfoFragment | undefined
    lastVisitedTitle: string
    allCpAggregateInfo: (DescriptionCardType | null)[]
    newLection: LectionInfoFragment | undefined
}) {
    const { t } = useTranslation()

    return (
        <>
            <PageTitleWrapper>
                <Typography variant="h4" style={{ color: theme.palette.primary.light }}>
                    {t('base:welcome_back', { username: firstNameToDisplay })}
                </Typography>
            </PageTitleWrapper>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '2.5em',
                    justifyContent: 'space-between'
                }}>
                {lectionToContinue && (
                    <FocusComponents title={lastVisitedTitle} lectionInfo={lectionToContinue} />
                )}
                {newLection && newLection.lectionId !== lectionToContinue?.lectionId && (
                    <FocusComponents
                        title={t('base:continue_with_new_content')}
                        lectionInfo={newLection}
                    />
                )}
            </div>

            <Typography variant="h5" style={{ marginTop: '1.5em' }}>
                {' '}
                {t('base:contact_point_section_caption')}{' '}
            </Typography>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap'
                }}>
                {allCpAggregateInfo.map(
                    (cpAggregateInfo) =>
                        cpAggregateInfo && (
                            <ThirdWidthLayout key={cpAggregateInfo.title}>
                                <ThirdWidthDescriptionCard
                                    itemDetails={cpAggregateInfo}
                                    infoComponentSubsectionName={InfoComponentSubsection.LECTION}
                                />
                            </ThirdWidthLayout>
                        )
                )}
            </div>
        </>
    )
}

function onlyAssignUnlockedAndUnfinishedLection(lection: LectionInfoFragment | undefined) {
    if (!lection?.isLocked && lection?.completedCount < lection?.totalCount) {
        return lection
    } else {
        return undefined
    }
}

async function lastVisitedLectionBasedOnEvents({
    data,
    lectionId,
    loadSingleLection
}: {
    data: GetHomePageInfoQuery
    lectionId: number
    loadSingleLection: Function
}): Promise<[LectionInfoFragment | undefined, number | undefined]> {
    let singleLection: GetSingleLectionForHomepageQuery['progress_lection_overview'] | undefined =
        undefined
    if (data?.events && lectionId) {
        singleLection = (
            await loadSingleLection({
                variables: { lectionId: lectionId },
                fetchPolicy: 'network-only' //refetches the query on every navigation
            })
        ).data?.progress_lection_overview
    }
    // use last viewed lection as lectionToContinue if suitable to continue
    return [
        onlyAssignUnlockedAndUnfinishedLection(singleLection?.[0]),
        singleLection?.[0]?.contactpoint?.id
    ]
}

async function suggestLectionOfUnfinishedCp({
    lastViewedContactpointId,
    handNextLection,
    footNextLection,
    pelvisNextLection
}: {
    lastViewedContactpointId: number
    handNextLection: LectionInfoFragment | undefined
    footNextLection: LectionInfoFragment | undefined
    pelvisNextLection: LectionInfoFragment | undefined
}) {
    const lastHandledContactPoint =
        CONTACT_POINT_ID_TO_SECTION_NAMES_MAPPING[lastViewedContactpointId ?? -1]

    // find the contactpoint specific next lection
    let nextLection
    switch (lastHandledContactPoint?.[0]) {
        case SectionNames.HAND:
            nextLection = handNextLection
            break
        case SectionNames.FOOT:
            nextLection = footNextLection
            break
        case SectionNames.PELVIS:
            nextLection = pelvisNextLection
    }

    return onlyAssignUnlockedAndUnfinishedLection(nextLection)
}

async function getLectionOfUnfinishedChapter({
    loadAllUnfinishedChapter,
    loadSingleLection
}: {
    loadAllUnfinishedChapter: LazyQueryExecFunction<
        GetUnfinishedChapterIdsQuery,
        Exact<{ [p: string]: never }>
    >
    loadSingleLection: Function
}) {
    const allUnfinishedChapter = (
        await loadAllUnfinishedChapter({
            fetchPolicy: 'network-only' //refetches the query on every navigation
        })
    ).data

    for (let unfinishedChapter of allUnfinishedChapter?.progress_chapter_overview || []) {
        const singleLection = (
            await loadSingleLection({
                variables: { lectionId: unfinishedChapter?.lectionId },
                fetchPolicy: 'network-only' //refetches the query on every navigation
            })
        ).data?.progress_lection_overview

        let lectionOfChapter = singleLection?.[0]
        if (
            !lectionOfChapter?.isLocked &&
            lectionOfChapter?.completedCount < lectionOfChapter?.totalCount
        ) {
            return lectionOfChapter
        }
    }
}

export default function HomeComponent({ newLectionId }: { newLectionId: number | undefined }) {
    const { t } = useTranslation()

    const [loadSingleLection] = useGetSingleLectionForHomepageLazyQuery()

    const { data } = useGetHomePageInfoQuery({
        variables: {
            handCpIds: SECTION_NAMES_TO_CONTACT_POINT_ID_MAPPING[SectionNames.HAND],
            footCpIds: SECTION_NAMES_TO_CONTACT_POINT_ID_MAPPING[SectionNames.FOOT],
            pelvisCpIds: SECTION_NAMES_TO_CONTACT_POINT_ID_MAPPING[SectionNames.PELVIS]
        },
        fetchPolicy: 'network-only' //refetches the query on every navigation
    })

    const username = data?.user_details?.[0]?.user?.name
    const firstName = username?.split(' ')?.[0]
    const firstNameToDisplay = firstName ? `, ${firstName}` : ''

    const [handCpAggregateInfo, handNextLection] = toAggregatedContactpointInfo(
        data?.handAggregationInfo,
        t('base:hand')
    )

    const [footCpAggregateInfo, footNextLection] = toAggregatedContactpointInfo(
        data?.footAggregationInfo,
        t('base:foot')
    )

    const [pelvisCpAggregateInfo, pelvisNextLection] = toAggregatedContactpointInfo(
        data?.pelvisAggregationInfo,
        t('base:pelvis')
    )

    const allCpAggregateInfo = [pelvisCpAggregateInfo, handCpAggregateInfo, footCpAggregateInfo]

    const allAggregatedInfo = []
    allAggregatedInfo.push(data?.handAggregationInfo)
    allAggregatedInfo.push(data?.footAggregationInfo)
    allAggregatedInfo.push(data?.pelvisAggregationInfo)

    let lastVisitedTitle = t('base:continue_directly')

    const [lectionToContinue, setLectionToContinue] = useState<LectionInfoFragment | undefined>(
        undefined
    )

    const [loadAllUnfinishedChapter] = useGetUnfinishedChapterIdsLazyQuery()

    // load lection that was visited most recently
    const lectionId = data?.events?.[0]?.parameters?.lectionId

    async function findNextLection() {
        setLectionToContinue(undefined)
        try {
            //0. Suggest the lection that was last visited (based on event)
            var lastViewedContactpointId: number | undefined
            if (data) {
                const [continueLection, lastViewedContactpointId] =
                    await lastVisitedLectionBasedOnEvents({
                        data,
                        lectionId,
                        loadSingleLection
                    })

                if (continueLection) {
                    setLectionToContinue(continueLection)
                    return
                }

                // If no lection found so far -> last viewed lection either finished or not existing -> suggest the next lection
                // We find the best next lection to suggest in steps

                // 1. Suggest the next lection based on the contactpoint that was visited recently.
                if (lastViewedContactpointId) {
                    const continueLection = await suggestLectionOfUnfinishedCp({
                        lastViewedContactpointId,
                        handNextLection,
                        footNextLection,
                        pelvisNextLection
                    })
                    if (continueLection) {
                        setLectionToContinue(continueLection)
                        return
                    }
                }

                // 2. If all lections of cp completed... Suggest a following lection of one of the other contactpoints hand, foot, pelvis. If all lections of hand, foot, pelvis cp completed...
                if (lectionToContinue === undefined) {
                    const continueLection = [
                        pelvisNextLection,
                        handNextLection,
                        footNextLection
                    ].find((lection) => lection?.isLocked === false)

                    if (continueLection) {
                        setLectionToContinue(continueLection)
                        return
                    }
                }

                // 3. Suggest a random lection that wasn't finished so far
                // if still no unfinished lection found get lection of a still unfinished and not locked chapter to continue from there
                // ASSUMPTION: if the execution reaches this code snippet, all elementary contents for hand, foot and pelvis are finished thus no locked chapter exists anymore
                // this means the first chapter should directly be unlocked.
                if (lectionToContinue === undefined) {
                    const continueLection = await getLectionOfUnfinishedChapter({
                        loadAllUnfinishedChapter,
                        loadSingleLection
                    })

                    if (continueLection) {
                        setLectionToContinue(continueLection)
                        return
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const [newLection, setNewLection] = useState<LectionInfoFragment | undefined>(undefined)
    const [loadNewLection] = useGetSingleLectionForHomepageLazyQuery()

    async function findNewLection() {
        if (newLectionId !== lectionToContinue?.lectionId) {
            const fetchedNewLection = (
                await loadNewLection({
                    variables: { lectionId: newLectionId },
                    fetchPolicy: 'network-only' //refetches the query on every navigation
                })
            ).data?.progress_lection_overview

            setNewLection(fetchedNewLection?.[0])
        } else {
            setNewLection(undefined)
        }
    }

    useEffect(() => {
        findNextLection()
        findNewLection()
    }, [data, data?.events, newLectionId])

    return (
        <HomeContent
            firstNameToDisplay={firstNameToDisplay}
            lectionToContinue={lectionToContinue}
            lastVisitedTitle={lastVisitedTitle}
            allCpAggregateInfo={allCpAggregateInfo}
            newLection={newLection}
        />
    )
}
