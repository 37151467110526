export default {
    new: 'Nieuw',
    submit: 'Verzenden',
    cancel: 'Annuleren',
    logout: 'Uitloggen',
    login: 'Inloggen',
    course: 'Cursus',
    lection: 'Les',
    chapter: 'Hoofdstuk',
    back: 'Terug',
    next: 'Verder',
    of: 'van',
    finish: 'Afwerking',
    error_loading_content: 'Fout bij het laden van de inhoud',
    hand: 'Handen',
    foot: 'Voeten',
    pelvis: 'Bekken',
    profiler: 'Profiler',
    products: 'Producten',
    intro: 'Intro',
    getting_started: 'Meteen aan de slag:',
    continue_directly: 'Ga verder waar je gebleven was:',
    continue_with_new_content: 'Aan de slag met de nieuwe inhoud:',
    contact_point_section_caption: 'Doorgaan bij een contactpunt:',
    welcome_back: 'Welkom terug{{username}}!'
}
