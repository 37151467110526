import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useChangeLanguageMutation } from '../../generated/graphql'
import { toast } from 'react-toastify'

interface Props {
    isLoggedIn: boolean
}

export function SmallLanguageSwitcher({ isLoggedIn }: Props) {
    const { i18n } = useTranslation()
    const [changeLanguage] = useChangeLanguageMutation()
    const lang = i18n.language
    const setLang = async (lang: string) => {
        // If a user is logged in, the language is persisted in SQbase. If not, it is only updated locally.
        if (isLoggedIn) {
            const { data } = await changeLanguage({
                variables: {
                    language: lang
                }
            })
            await toast.success(`Language changed to ${data?.update_user?.returning[0]?.language}`)
        }

        await i18n.changeLanguage(lang)
        console.log('Switched language to ' + lang)
    }

    const langs = [
        { lang: 'de', emoji: '🇩🇪' },
        { lang: 'en', emoji: '🇺🇸' },
        { lang: 'nl', emoji: '🇳🇱' }
    ]
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            {langs.map((it) => (
                <Button
                    style={{ opacity: lang === it.lang ? 1 : 0.5 }}
                    onClick={() => setLang(it.lang)}
                    key={it.lang}>
                    <Typography>{it.emoji}</Typography>
                </Button>
            ))}
        </div>
    )
}
