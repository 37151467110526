import React from 'react'

const BlockContent = require('@sanity/block-content-to-react')

export default function CustomSanityBlock({ blocks }: any) {
    const serializers = {
        marks: {
            link: ({ mark, children }: any) => {
                const { href } = mark
                return (
                    <a
                        href={href}
                        style={{ color: 'var(--sq-orange)' }}
                        target="_blank"
                        rel="noopener">
                        {children}
                    </a>
                )
            }
        },
        list: (props: any) => <ul style={{ marginLeft: '50px' }}>{props.children}</ul>,
        listItem: (props: any) => <li>{props.children}</li>,
        text: (props: any) => <p style={{ marginBottom: '10px' }}>{props.children}</p>,
        link: (props: any) => <a style={{ marginBottom: '10px' }}>hallo</a>,
        types: {
            // TODO: Render youtube videos in the text?
            youtube: (node: any) => {
                return <div />
            }
        }
    }
    return <BlockContent blocks={blocks} serializers={serializers} />
}
