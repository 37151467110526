import { DescriptionCardType, useDescriptionCardStyles } from './FullWidthDescriptionCard'
import { Card } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { BorderLinearProgress } from '../../LogicalComponents/ProfileComponent'
import { LockedCardImageComponent } from '../course/LockedCardImageComponent'
import TitleComponent from './DescriptionCardElements/TitleComponent'
import InfoComponent, { InfoComponentSubsection } from './DescriptionCardElements/InfoComponent'

export const useThirdWidthDescriptionCard = makeStyles({
    thirdWidthCard: { display: 'grid', gridTemplateColumns: '1fr 1fr' },
    imageStyle: { margin: '1em' },
    titleStyle: { margin: '1em' },
    infoStyle: { margin: '1em', marginBottom: 0 }
})

export default function ThirdWidthDescriptionCard({
    itemDetails,
    infoComponentSubsectionName
}: {
    itemDetails: DescriptionCardType
    infoComponentSubsectionName: InfoComponentSubsection
}) {
    //necessary definitions
    const history = useHistory()
    const { t } = useTranslation()

    //import styles
    const cardStyle = useDescriptionCardStyles()
    const thirdWidthCardStyle = useThirdWidthDescriptionCard()

    const { isLocked, redirectUrl, isCompleted, completedCount, totalCount, imgUrl, title } =
        itemDetails

    // only make Card grey if the lection is marked as completed (only contains whether fundamental lections are finished)
    // and all lections are completed
    const markAsFinished = isCompleted && (completedCount ?? 0) === totalCount

    return (
        <Card
            variant="outlined"
            onClick={!isLocked ? () => history.push(redirectUrl) : undefined}
            className={`${cardStyle.root} ${thirdWidthCardStyle.thirdWidthCard}`}
            style={{
                opacity: markAsFinished ? 0.5 : 1
            }}>
            <LockedCardImageComponent
                isLocked={isLocked}
                lockedText={t('courses:locked')}
                lockedToolTip={t('courses:tooltip_locked')}
                img_url={imgUrl}
                title={title}
                className={thirdWidthCardStyle.imageStyle}
            />
            <div>
                <TitleComponent
                    titleInfo={itemDetails}
                    className={`${thirdWidthCardStyle.titleStyle}`}
                />

                <InfoComponent
                    infoDetails={itemDetails}
                    subsectionName={infoComponentSubsectionName}
                    className={`${thirdWidthCardStyle.infoStyle}`}
                />
                {
                    <BorderLinearProgress
                        variant="determinate"
                        value={((completedCount ?? 0) / totalCount) * 100}
                        style={{ margin: '1em', marginTop: 0 }}
                    />
                }
            </div>
        </Card>
    )
}
