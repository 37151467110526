import React, { useEffect, useState } from 'react'
import { t, TFunction } from 'i18next'
import AdjustOutlinedIcon from '@material-ui/icons/AdjustOutlined'
import {
    BookmarkBorder,
    CollectionsBookmark,
    Home,
    PanTool,
    PlaylistPlay,
    ShoppingBasket,
    TabletMac,
    VideoLibraryOutlined
} from '@material-ui/icons'
import {
    ActiveCoursePath,
    ActiveFinalQuizPath,
    FootPath,
    HandPath,
    HomePath,
    InstallationVideoPath,
    IntroPath,
    PelvisPath,
    ProductsPath,
    ProfilerPath
} from './RouteConstants'
import { GiBarefoot, GiPelvisBone } from 'react-icons/gi'
import HomeComponent from '../Components/NonLogicalComponents/home/HomeComponent'
import SectionComponent, {
    SectionNames
} from '../Components/NonLogicalComponents/course/SectionComponent'
import CourseActivePage from '../Views/courses/CourseActivePage'
import FinalQuizPage from '../Views/quiz/FinalQuizPage'
import InstallationVideoPage from '../Views/videos/InstallationVideoPage'
import {
    useAllLectionsCreatedAtSubscription,
    useGetUserCreationQuery,
    useLectionLockingProgressSubscription,
    useVisitedLectionsEventsSubscription
} from '../generated/graphql'
import { whatIsNewLogic } from '../Components/LogicalComponents/WhatIsNew/WhatIsNewLogic'

export interface RouteDefinition {
    id: number
    path: string
    icon: JSX.Element
    component: JSX.Element
    name: JSX.Element
    mobileOnly: boolean
    hidden: boolean
    subroute?: boolean
    lection?: any
    newChapterCount: number | undefined
}

function RouteItem({ routeName }: { routeName: string }) {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%'
            }}>
            <div style={{ display: 'flex', alignSelf: 'center' }}>{routeName}</div>
        </div>
    )
}

export const SECTION_NAMES_TO_CONTACT_POINT_ID_MAPPING = {
    [SectionNames.INTRO]: [613],
    [SectionNames.PELVIS]: [612, 5285],
    [SectionNames.HAND]: [1420, 6147],
    [SectionNames.FOOT]: [1654, 6215],
    [SectionNames.PROFILER]: [6998],
    [SectionNames.PRODUCTS]: [11681]
}

export const CONTACT_POINT_ID_TO_SECTION_NAMES_MAPPING: Record<number, string> = {}
for (let [contactPointName, cpIds] of Object.entries(SECTION_NAMES_TO_CONTACT_POINT_ID_MAPPING)) {
    for (let cpId of cpIds) {
        CONTACT_POINT_ID_TO_SECTION_NAMES_MAPPING[cpId] = contactPointName
    }
}

export function getRoutes(t: TFunction): Array<RouteDefinition> {
    // subscription for all lections (content)
    const { data: lectionsWithCreatedAt } = useAllLectionsCreatedAtSubscription()
    // get clicked lection events
    const { data: visitedLectionsEvents } = useVisitedLectionsEventsSubscription()
    // get locking status of lections
    const { data: lectionLockingStatus } = useLectionLockingProgressSubscription()
    // get time when user was created
    const { data: userCreationTime } = useGetUserCreationQuery()

    // hook for: lectionId of a new lection that can be displayed in the what is new section on the homepage
    const [newLectionIdHook, setNewLectionIdHook] = useState<number>()
    // hook for: grouped by contact points: all new chapter counted
    const [newChapterCountByContactPointsHook, setNewChapterCountByContactPointsHook] =
        useState<Record<string, number>>()
    // hook for: stored by lectionId: number of new chapters for each lection
    const [newChapterCountByLectionIdHook, setNewChapterCountByLectionIdHook] =
        useState<Record<string, number>>()

    useEffect(() => {
        whatIsNewLogic(
            lectionLockingStatus,
            visitedLectionsEvents,
            lectionsWithCreatedAt,
            setNewChapterCountByLectionIdHook,
            setNewLectionIdHook,
            setNewChapterCountByContactPointsHook,
            userCreationTime
        )
    }, [lectionsWithCreatedAt, visitedLectionsEvents, lectionLockingStatus, userCreationTime])

    return [
        {
            id: 1,
            path: HomePath,
            icon: <Home />,
            component: <HomeComponent newLectionId={newLectionIdHook} />,
            name: t('nav:home'),
            mobileOnly: false,
            hidden: false,
            newChapterCount: undefined
        },
        {
            id: 2,
            path: IntroPath,
            icon: <PlaylistPlay />,
            component: (
                <SectionComponent
                    contactPointName={SectionNames.INTRO}
                    contactPointIds={SECTION_NAMES_TO_CONTACT_POINT_ID_MAPPING[SectionNames.INTRO]}
                    newChapterCount={newChapterCountByLectionIdHook}
                />
            ),
            name: <RouteItem routeName={t('base:intro')} />,
            mobileOnly: false,
            hidden: false,
            newChapterCount: newChapterCountByContactPointsHook?.[SectionNames.INTRO]
        },
        {
            id: 3,
            path: PelvisPath,
            icon: <GiPelvisBone size={'2em'} />,
            component: (
                <SectionComponent
                    contactPointName={SectionNames.PELVIS}
                    contactPointIds={SECTION_NAMES_TO_CONTACT_POINT_ID_MAPPING[SectionNames.PELVIS]}
                    newChapterCount={newChapterCountByLectionIdHook}
                />
            ),
            name: <RouteItem routeName={t('base:pelvis')} />,
            mobileOnly: false,
            hidden: false,
            newChapterCount: newChapterCountByContactPointsHook?.[SectionNames.PELVIS]
        },
        {
            id: 4,
            path: HandPath,
            icon: <PanTool />,
            component: (
                <SectionComponent
                    contactPointName={SectionNames.HAND}
                    contactPointIds={SECTION_NAMES_TO_CONTACT_POINT_ID_MAPPING[SectionNames.HAND]}
                    newChapterCount={newChapterCountByLectionIdHook}
                />
            ),
            name: <RouteItem routeName={t('base:hand')} />,
            mobileOnly: false,
            hidden: false,
            newChapterCount: newChapterCountByContactPointsHook?.[SectionNames.HAND]
        },
        {
            id: 5,
            path: FootPath,
            icon: <GiBarefoot size={'2em'} />,
            component: (
                <SectionComponent
                    contactPointName={SectionNames.FOOT}
                    contactPointIds={SECTION_NAMES_TO_CONTACT_POINT_ID_MAPPING[SectionNames.FOOT]}
                    newChapterCount={newChapterCountByLectionIdHook}
                />
            ),
            name: <RouteItem routeName={t('base:foot')} />,
            mobileOnly: false,
            hidden: false,
            newChapterCount: newChapterCountByContactPointsHook?.[SectionNames.FOOT]
        },
        {
            id: 6,
            path: ProductsPath,
            icon: <ShoppingBasket />,
            component: (
                <SectionComponent
                    contactPointName={SectionNames.PRODUCTS}
                    contactPointIds={
                        SECTION_NAMES_TO_CONTACT_POINT_ID_MAPPING[SectionNames.PRODUCTS]
                    }
                    newChapterCount={newChapterCountByLectionIdHook}
                />
            ),
            name: <RouteItem routeName={t('base:products')} />,
            mobileOnly: false,
            hidden: false,
            newChapterCount: newChapterCountByContactPointsHook?.[SectionNames.PRODUCTS]
        },
        {
            id: 7,
            path: ProfilerPath,
            icon: <TabletMac />,
            component: (
                <SectionComponent
                    contactPointName={SectionNames.PROFILER}
                    contactPointIds={
                        SECTION_NAMES_TO_CONTACT_POINT_ID_MAPPING[SectionNames.PROFILER]
                    }
                    newChapterCount={newChapterCountByLectionIdHook}
                />
            ),
            name: <RouteItem routeName={t('base:profiler')} />,
            mobileOnly: false,
            hidden: false,
            newChapterCount: newChapterCountByContactPointsHook?.[SectionNames.PROFILER]
        },
        {
            id: 8,
            path: InstallationVideoPath,
            icon: <VideoLibraryOutlined />,
            component: <InstallationVideoPage />,
            name: <RouteItem routeName={t('installationVideos:installation')} />,
            mobileOnly: false,
            hidden: false,
            newChapterCount: undefined
        },
        {
            id: 13,
            path: ActiveCoursePath + '/:id',
            icon: <AdjustOutlinedIcon />,
            component: <CourseActivePage />,
            name: <p>Active Course</p>,
            hidden: true,
            mobileOnly: false,
            newChapterCount: undefined
        },
        {
            id: 14,
            path: ActiveFinalQuizPath + '/:id',
            icon: <AdjustOutlinedIcon />,
            component: <FinalQuizPage />,
            name: <p>Active Final Quiz</p>,
            hidden: true,
            mobileOnly: false,
            newChapterCount: undefined
        }
    ]
}
