export const setUserToLocalStorage = (name: string, password: string) => {
    const base64User = b64EncodeUnicode(name + ':' + password)
    saveStateToLocalStorage(base64User, PROFILE_AUTH_KEY)
}

export const resetAuth = () => {
    removeUser()
}

function b64EncodeUnicode(str: string) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(
        encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
            // @ts-ignore
            return String.fromCharCode(`0x${p1}`)
        })
    )
}

const PROFILE_AUTH_KEY = 'sqLabProfileOnly'

export const readUserfromLocalStorage = () => {
    return loadAuthTokenFromLocalStorage()
}

export const removeUser = () => {
    localStorage.removeItem(PROFILE_AUTH_KEY)
}

export const loadAuthTokenFromLocalStorage = () => {
    try {
        const serializedState = localStorage.getItem(PROFILE_AUTH_KEY)
        if (serializedState === null) {
            return undefined
        }
        return serializedState.replaceAll('"', '')
    } catch (err) {
        return undefined
    }
}

export const saveStateToLocalStorage = (state: any, key: string) => {
    try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem(key, serializedState)
    } catch {
        // ignore write errors
    }
}
