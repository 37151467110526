import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import { CheckCircle, Lock, PlayCircleFilled } from '@material-ui/icons'
import React from 'react'
import { theme } from '../../../../Apollo'

type CircularProgressIndicatorType = {
    isLocked: boolean
    completedCount?: number
    totalCount: number
    isCompleted?: boolean
}

export default function CircularProgressIndicator({
    progressInfo,
    className
}: {
    progressInfo: CircularProgressIndicatorType
    className?: string
}) {
    // parameter extraction
    const { isLocked, completedCount, totalCount, isCompleted } = progressInfo
    const completedCountValue = completedCount ?? totalCount // if completedCount is undefined, set completedCountValue to total count to ensure allLectionsCompleted becomes true (as it's a quiz)

    const allLectionsCompleted = completedCountValue >= totalCount

    // icon should be checkmark if content is indicated as completed and all lections are indeed completed
    // icon should be a lock if lection is locked
    let icon

    isCompleted && allLectionsCompleted
        ? (icon = <CheckCircle fontSize="large" />)
        : isLocked
        ? (icon = <Lock fontSize="large" />)
        : (icon = <PlayCircleFilled fontSize="large" />)

    const completedPercentage = Math.round((completedCountValue / totalCount) * 100)

    return (
        <>
            {completedCount !== undefined ? (
                <div className={className} style={{ width: '5.5em' }}>
                    <CircularProgressbarWithChildren
                        value={completedPercentage}
                        // Customize the root svg element
                        styles={{
                            // Customize the path, i.e. the "completed progress"
                            path: {
                                // Path color
                                stroke: theme.palette.primary.light
                            },
                            // Customize the circle behind the path, i.e. the "total progress"
                            trail: {
                                // Trail color
                                stroke: '#d6d6d6'
                            }
                        }}>
                        {icon}
                        <p>{`${completedPercentage}%`}</p>
                    </CircularProgressbarWithChildren>
                </div>
            ) : (
                <div className={className} style={{ display: 'flex' }}>
                    {icon}
                </div>
            )}
        </>
    )
}
