import styled from 'styled-components'

export const Layout = styled.div`
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-flex: 1;
    width: 100%;
    flex-shrink: 0;
`
export const LeftSection = styled.div`
    min-width: 350px;
    max-width: 350px;
    width: 100%;
    top: 0px;
    bottom: 0;
    left: 0;
    background: white;
    border-radius: 20px;
    margin-right: 20px;
    box-sizing: border-box;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const RightSection = styled.div`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
`

export {}
