export default {
    error_name_not_empty: 'Der Name darf nicht leer sein.',
    error_password_not_empty: 'Das Passwort darf nicht leer sein.',
    error_name_too_long: 'Profil Name ist zu lang.',
    create_user: 'Neues Profil anlegen',
    name: 'Vorname',
    surname: 'Nachname',
    email: 'E-Mail Addresse',
    password: 'Profil Passwort',
    password_repeat: 'Passwort wiederholen',
    createBtn: 'Profil anlegen',
    sort: 'Sortieren',
    frequently_used: 'Zuletzt eingeloggt',
    exp: 'Erfahrungspunkte',
    logout: 'Ausloggen',
    profile_overview: 'Zurück zu Login',
    invalidMail: 'Ungültige E-Mail Addresse.',
    vendor_number: 'Kundennummer',
    error_password_not_matching: 'Die Passwörter stimmen nicht überein.',
    change_password_desc: 'Gib jetzt ein neues Passwort für dein Profil ein!',
    change_password: 'Passwort ändern',
    set_new_password: 'Neues Passwort setzen',
    change_language: 'Sprache ändern zu',
    change_password_min_error: 'Mindestens 7 Zeichen',
    change_password_req_error: 'Benötigt',
    error_customer_number: 'Die Kundennummer ist unvollständig.',
    points_info:
        'Herzlichen Glückwunsch, du hast alle Inhalte erfolgreich durchgearbeitet. Die übrigen Punkte sind bereits für neue Inhalte reserviert. Sei gespannt darauf.'
}
