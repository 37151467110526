import { Dispatch, SetStateAction, useEffect } from 'react'
import {
    CONTACT_POINT_ID_TO_SECTION_NAMES_MAPPING,
    SECTION_NAMES_TO_CONTACT_POINT_ID_MAPPING
} from '../../../router/routes'
import {
    AllLectionsCreatedAtSubscription,
    GetUserCreationQuery,
    LectionLockingProgressSubscription,
    VisitedLectionsEventsSubscription
} from '../../../generated/graphql'

function mapLockedStatusToLectionId(
    lectionLockingStatus: LectionLockingProgressSubscription | undefined
) {
    // key: lection id, value: bool whether lection is locked or not
    const lectionLockingStatusById: Record<number, boolean> = {}
    lectionLockingStatus?.progress_lection_overview.forEach((lection) => {
        if (lection.lectionId) {
            lectionLockingStatusById[lection.lectionId] = lection.isLocked ?? false
        }
    })
    return lectionLockingStatusById
}

function mapEventTimestampToLectionId(
    visitedLectionsEvents: VisitedLectionsEventsSubscription | undefined
) {
    // Filter the array to get the most recent "lection visited event" for each lectionId
    // (hasura request is using distinct but due to different chapterIds for a lectionId, lectionIds are not unique)
    const mostRecentVisitedLectionsEvents: Record<
        number,
        {
            contactPointId: number
            eventCreatedAt: string
        }
    > = {}
    visitedLectionsEvents?.events?.forEach((current) => {
        const existingEntry = mostRecentVisitedLectionsEvents[current.parameters.lectionId]
        // If there is no entry with the same lectionId
        // or the existingEntry was earlier than the current event add/ replace event in the mostRecentVisitedLectionsEvents
        if (!existingEntry || existingEntry.eventCreatedAt < current.eventCreatedAt) {
            mostRecentVisitedLectionsEvents[current.parameters.lectionId] = {
                contactPointId: current.parameters.contactPointId,
                eventCreatedAt: current.eventCreatedAt
            }
        }
    })

    return mostRecentVisitedLectionsEvents
}

export function whatIsNewLogic(
    lectionLockingStatus: LectionLockingProgressSubscription | undefined,
    visitedLectionsEvents: VisitedLectionsEventsSubscription | undefined,
    lectionsWithCreatedAt: AllLectionsCreatedAtSubscription | undefined,
    setNewChapterCountByLectionIdHook: Dispatch<SetStateAction<Record<string, number> | undefined>>,
    setNewLectionIdHook: Dispatch<SetStateAction<number | undefined>>,
    setNewChapterCountByContactPointsHook: Dispatch<
        SetStateAction<Record<string, number> | undefined>
    >,
    userCreationTime: GetUserCreationQuery | undefined
) {
    //lectionId of a new lection that can be displayed in the "what is new" section on the homepage
    let newLectionId
    const lectionLockingStatusById = mapLockedStatusToLectionId(lectionLockingStatus)

    const mostRecentVisitedLectionsEvents = mapEventTimestampToLectionId(visitedLectionsEvents)

    // grouped by contact points: all new chapter counted
    const newChapterCountByContactPoints: Record<string, number> = {}
    Object.keys(SECTION_NAMES_TO_CONTACT_POINT_ID_MAPPING).forEach((key) => {
        newChapterCountByContactPoints[key] = 0
    })

    // stored by lectionId: number of new chapters for each lection
    const newChapterCountByLectionId: Record<string, number> = {}

    const userCreationTimestamp = userCreationTime?.user[0].userCreatedAt

    lectionsWithCreatedAt?.contentLections.forEach((contentLection) => {
        // find event with lection id
        const correspondingEvent = mostRecentVisitedLectionsEvents[contentLection.lectionId]

        // find contact point name based on contact point id of lection
        let contactPointSectionName =
            CONTACT_POINT_ID_TO_SECTION_NAMES_MAPPING[contentLection.contactPointId]

        // count chapters that were created after clicking the corresponding lection
        // (in this case the event timestamp of clicking the lection is considered as otherwise we could always only
        // indicate one new chapter - if we would indicate all new chapter and only register them as seen, locked
        // lections would be counted and marked as new until they are unlocked and clicked once)
        // only unlocked lections are considered and only chapter that were created after the user registered are considered
        contentLection.chapters.forEach((chapter) => {
            const lectionNotLocked = !lectionLockingStatusById[contentLection.lectionId]
            const chapterNewForUser = chapter.chapterCreatedAt > userCreationTimestamp
            const currentChapterIsNewer =
                chapter.chapterCreatedAt > correspondingEvent?.eventCreatedAt

            if (
                (lectionNotLocked && chapterNewForUser && !correspondingEvent) ||
                currentChapterIsNewer
            ) {
                // count increase count grouped by lectionId (for every new chapter)
                newChapterCountByLectionId[contentLection.lectionId] =
                    (newChapterCountByLectionId[contentLection.lectionId] ?? 0) + 1
                newLectionId = contentLection.lectionId
            }
        })

        // add number of new chapter of currently considered lection count of new chapter grouped by contact points
        newChapterCountByContactPoints[contactPointSectionName] =
            (newChapterCountByContactPoints[contactPointSectionName] ?? 0) +
            (newChapterCountByLectionId[contentLection.lectionId] ?? 0)
    })

    if (newLectionId === undefined) {
        newLectionId = -1
    }

    setNewChapterCountByLectionIdHook(newChapterCountByLectionId)
    setNewLectionIdHook(newLectionId)
    setNewChapterCountByContactPointsHook(newChapterCountByContactPoints)
}
