export default {
    overview: 'Eindtoetsen',
    finaltest: 'Eindtoetsen',
    explanation:
        'Met de eindtoets kunt u uw kennis over SQlab testen. Voor elke vraag die u goed beantwoord, ontvangt u punten. Wanneer u de toets goed heeft afgelegd, ontvangt u per e-mail een certificaat voor uw prestaties.',
    completed_at: 'Voltooid op',
    completed: 'Voltooid',
    ongoing: 'Nieuw',
    nothinghere_available:
        'Geen verdere testen beschikbaar. Kijk uit naar de volgende inhoudsupdate!',
    nothinghere_completed:
        'Geen testen gedaan. Als je een test hebt vrijgespeeld, kun je je certificaat verdienen!',
    tooltip_locked:
        'Eindtoetsen kunnen pas worden begonnen als alle lessen van het contactpunt zijn afgerond.',
    locked: 'Nog niet ontgrendeld',
    finalquiz_start_btn: 'Start eindtoets',
    disclaimer_correct_answer:
        'Kruis de juiste antwoorden aan. Meerdere antwoorden kunnen juist zijn. Een vraag is beantwoord wanneer alle goede antwoorden en geen foute antwoorden zijn aangekruist.',
    quiz_passed:
        'Gefeliciteerd, u bent geslaagd! Uw certificaat zal binnenkort per e-mail naar u worden verzonden.',
    quiz_failed: 'Helaas, u heeft te veel vragen fout beantwoord. Maar geen zorgen, u kunt het meteen opnieuw proberen!',
    noPoints: 'Je hebt de test al een keer gedaan, dus er zijn geen nieuwe punten.',
    question: 'Vraag',
    question_plural: 'Vragen',
    repeat_test: 'Doe de toets opnieuw',
    evaluation: 'Evaluatie'
}
