import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { TopNav } from '../../Components/NonLogicalComponents/layout/TopNav'
import { useParams } from 'react-router-dom'
import { FinalTestQuestion } from './FinalQuizQuestion'
import { useCreateQuizSessionMutation, useGetDetailedQuizQuery } from '../../generated/graphql'
import { QuizResultCard } from './QuizResultCard'
import { useTranslation } from 'react-i18next'
import { FinalQuizStartCard } from './FinalQuizStartCard'
import { useCustomTrans } from '../../i18n/UseCustomTrans'
import { HomePath } from '../../router/RouteConstants'

function FinalQuizPage() {
    const history = useHistory()
    const params: any = useParams()
    const quizId = parseInt(params['id'])
    const { data, loading } = useGetDetailedQuizQuery({ variables: { id: quizId } })
    const [newSession] = useCreateQuizSessionMutation()
    const [sessionID, setSessionID] = useState('')
    const [questionIndex, setQuestionIndex] = useState(-1)
    const { t } = useTranslation('finalquiz')
    const tr = useCustomTrans()

    const quiz = data?.quiz

    const getSessionID = async () => {
        const { data } = await newSession({
            variables: { quiz_id: quizId }
        })
        const sessionID = data?.session?.id
        setSessionID(sessionID)
    }

    useEffect(() => {
        if (data) {
            getSessionID()
        }
    }, [data])

    if (loading || !quiz) return <h1>Loading...</h1>

    const showEval = sessionID && data && questionIndex >= quiz.questions.length

    return (
        <div>
            <TopNav>
                {/*<Button onClick={() => history.push(FinalQuizzesOverviewPath)}>*/}
                {/*    <ArrowBackIosIcon /> {t('finalquiz:overview')}*/}
                {/*</Button>*/}
            </TopNav>
            <br />
            {questionIndex === -1 && quiz && (
                <FinalQuizStartCard
                    paragraph={tr(quiz?.description_i18n)}
                    imageURL={quiz?.img_url}
                    title={tr(quiz?.title_i18n)}
                    onClicked={() => setQuestionIndex(0)}
                />
            )}
            <br />
            {sessionID &&
                quiz?.questions?.map(
                    (question, index) =>
                        index === questionIndex && (
                            <FinalTestQuestion
                                quiz={quiz}
                                key={question.id}
                                question={question}
                                index={index}
                                sessionID={sessionID}
                                onAnswered={() => setQuestionIndex(questionIndex + 1)}
                                onBack={() =>
                                    questionIndex > 0 && setQuestionIndex(questionIndex - 1)
                                }
                            />
                        )
                )}

            {showEval && (
                <QuizResultCard
                    onFinished={() => history.push(HomePath)}
                    onRetry={async () => {
                        await getSessionID()
                        setQuestionIndex(0)
                    }}
                    sessionID={sessionID}
                    quizID={quizId}
                />
            )}
        </div>
    )
}

export default FinalQuizPage
