import { Button, Card, CardActions, CardContent, makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AnswerLetter, StyledAnswer } from '../../Components/NonLogicalComponents/StyledAnswer'
import {
    QuestionFragment,
    QuizFragment,
    useChoicesForQuestionQuery,
    useInsertQuizAnswersMutation
} from '../../generated/graphql'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useTranslation } from 'react-i18next'
import { shuffleArray } from '../../Utils/shuffleArray'
import { capitalizeFirstLetter } from '../../Utils/capitalize'
import { useCustomTrans } from '../../i18n/UseCustomTrans'
import { MultipleChoiceQuestion } from './MultipleChoiceQuestion'

const Alphabeth = ['A', 'B', 'C', 'D', 'E', 'F', 'G']

interface FinalTestQuestionParams {
    onAnswered: any
    onBack: any
    index: number
    sessionID: string
    quiz: QuizFragment
    question: QuestionFragment
}

export function FinalTestQuestion({
    quiz,
    onAnswered,
    onBack,
    question,
    index,
    sessionID
}: FinalTestQuestionParams) {
    const { t } = useTranslation('finalquiz')
    const tr = useCustomTrans()
    const classes = useStyles()
    const [activeAnswers, setActiveAnswers]: [number[], any] = useState([])
    const [waitingForEval, setWaitingForEval] = useState(false)
    const [sucess, setSucess] = useState('')
    const [mutateAnswer] = useInsertQuizAnswersMutation()
    const { data } = useChoicesForQuestionQuery({
        variables: {
            session_id: sessionID,
            question_id: question.id
        },
        fetchPolicy: 'network-only' //refetches the query on every navigation
    })
    const [shuffeledChoices, setShuffeledChoices] = useState(
        shuffleArray(question?.choices.slice(0) ?? [])
    )

    useEffect(() => {
        const mapPreviousChanges = () => {
            if (activeAnswers.length > 0) {
                return
            }
            const previousAnswerArr = data?.answers.map((choice) =>
                // This finds the global choice id as an index in the choice array of the question
                question.choices.findIndex((it) => it.id === choice.choice_id)
            )
            if (previousAnswerArr && previousAnswerArr.length > 0) {
                setActiveAnswers(previousAnswerArr)
                return
            }
            console.log('Did not find any matching previous answers')
        }
        mapPreviousChanges()
    }, [data])

    function toggleArrayItem(arr: number[], item: number) {
        if (arr.includes(item)) {
            return arr.filter((i) => i !== item)
        } else {
            return [...arr, item]
        }
    }

    const addAnswer = (answerIndex: number) => {
        const newArray = toggleArrayItem(activeAnswers, answerIndex)
        setActiveAnswers(newArray)
    }

    const handleAnswer = async () => {
        if (activeAnswers.length === 0) {
            return
        }
        let answers = activeAnswers.map((answer: number) => {
            return {
                session_id: sessionID,
                choice_id: shuffeledChoices[answer]['id']
            }
        })
        setWaitingForEval(true)
        await mutateAnswer({
            variables: {
                session_id: sessionID,
                objects: answers,
                question_id: question?.id
            }
        })

        setWaitingForEval(false)
        onAnswered()
    }

    return (
        <Layout>
            <TestCardWrapper>
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Typography variant="body1">{tr(quiz.title_i18n)}</Typography>
                        <br />
                        <MultipleChoiceQuestion
                            question={question}
                            shuffledChoices={shuffeledChoices}
                            activeAnswers={activeAnswers}
                            setActiveAnswers={setActiveAnswers}
                            titlePrefix={`${capitalizeFirstLetter(t('finalquiz:question'))} ${
                                index + 1
                            }: `}
                        />
                        {sucess && <h1>{sucess}</h1>}
                    </CardContent>

                    <CardActions className={classes.buttons}>
                        <Button onClick={() => onBack()} size="small">
                            {t('base:back')}
                        </Button>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            {index + 1} {t('base:of')} {quiz?.questions_aggregate.aggregate?.count}
                        </Typography>
                        {waitingForEval ? (
                            <CircularProgress />
                        ) : (
                            <Button size="small" onClick={() => handleAnswer()}>
                                {t('base:next')}
                            </Button>
                        )}
                    </CardActions>
                </Card>
            </TestCardWrapper>
        </Layout>
    )
}

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '20px'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)'
    },
    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between'
    }
})

const Layout = styled.div`
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-flex: 1;
    /* position: relative; */
    //height: 800px;
    width: 100%;
    flex-shrink: 0;
`

const TestCardWrapper = styled.div`
    width: 100%;
    background: white;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
`
