import styled from 'styled-components'
import { getScreenWidth, START_DESKTOP_VERSION } from '../../../App'

function getTopValue() {
    return getScreenWidth() < START_DESKTOP_VERSION ? '1em' : '0em'
}

export const PageTitleWrapper = styled.div`
    display: flex;
    padding-top: 3rem;
    margin-top: 1rem;
    position: -webkit-sticky;
    position: sticky;
    top: ${getTopValue};
    z-index: 10;
    background: var(--bg-color);
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: 768px) {
        padding-right: 10px;
        padding-left: 10px;
    }
`
