import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { useCustomTrans } from '../../i18n/UseCustomTrans'

interface EvaluationCheckboxesProps {
    questions: EvaluationQuestion[]
}

export interface EvaluationQuestion {
    title: string
    correct: boolean
    hint: string
}

export const EvaluationCheckboxes: FunctionComponent<EvaluationCheckboxesProps> = ({
    questions
}: EvaluationCheckboxesProps) => {
    let elementsToRender = []
    const { t } = useTranslation('finalquiz')
    const tr = useCustomTrans()

    for (let i = 0; i < questions.length; i++) {
        const question = questions[i]
        if (question.correct) {
            elementsToRender.push(<SuccItemComponent key={i} title={tr(question.title)} />)
        } else {
            elementsToRender.push(
                <WrongAnswerComponent key={i} title={tr(question.title)} hint={tr(question.hint)} />
            )
            // elementsToRender.push(<hr />)
        }
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h5" style={{ textTransform: 'capitalize' }}>
                {t('question', { count: 2 })}
            </Typography>
            {elementsToRender}
        </div>
    )
}

const QuestionWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: start;
`

const WrongItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;

    background: red;
    border: 1px solid var(--border-color);
    margin-right: 10px;

    & > svg {
        color: white;
        width: 30px;
    }
`

const SuccItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;

    height: 50px;
    min-width: 50px;
    border-radius: 50%;

    background: green;
    border: 1px solid var(--border-color);
    margin-right: 10px;

    & > svg {
        color: white;
        width: 30px;
    }
`

function SuccItemComponent({ title }: any) {
    return (
        <QuestionWrapper>
            <SuccItem>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 13l4 4L19 7"
                    />
                </svg>
            </SuccItem>
            <div style={{ textAlign: 'start', marginLeft: '10px' }}>
                <p>{title}</p>
            </div>
        </QuestionWrapper>
    )
}

function WrongAnswerComponent({ title, hint }: any) {
    return (
        <React.Fragment>
            <QuestionWrapper>
                <WrongItem>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </WrongItem>
                <div style={{ textAlign: 'start', marginLeft: '10px', flexWrap: 'wrap' }}>
                    <Typography>{title}</Typography>
                    <Typography variant="subtitle1" style={{ color: '#8da1b5' }}>
                        Tipp: {hint}
                    </Typography>
                </div>
            </QuestionWrapper>
            <QuestionWrapper></QuestionWrapper>
        </React.Fragment>
    )
}
