import base from './base'
import courses from './courses'
import finalquiz from './finalquiz'
import imprint from './imprint'
import rewardsModal from './rewardsModal'
import login from './login'
import nav from './nav'
import profile from './profile'
import installationVideos from './installationVideos'
import profiler from "./profiler";

export default {
    base,
    courses,
    finalquiz,
    imprint,
    login,
    nav,
    profile,
    rewardsModal,
    installationVideos,
    profiler

}
