export default {
    overview: 'Kursübersicht',
    startTest: 'Test Starten',
    nextChapter: 'Nächstes Kapitel',
    allcourses: 'Kursübersicht',
    completed: 'Abgeschlossen',
    ongoing: 'Neu',
    no_courses: 'Es sind leider keine weiteren Lektionen vorhanden.',
    nothinghere_completed: 'Noch keine Kurse abgeschlossen. Leg los mit einem neuen Kurs!',
    nothinghere_available:
        'Keine weiteren Kurse verfügbar. Sei gespannt auf das nächste Content Update!',
    quiz_wrong: 'Die Antwort war leider falsch.',
    quiz_right: 'Die Antwort war richtig!',
    quiz_submit: 'Absenden',
    quiz_cancel: 'Abbrechen',
    title_quiz: 'Verständnisfrage',
    tooltip_locked:
        'Dieser Kurs kann noch nicht gestartet werden, da nicht alle Voraussetzungen erfüllt sind.',
    locked: 'Noch nicht freigeschaltet',
    toast_quiz_answer_wrong: 'Diese Antwort war leider falsch!',
    toast_quiz_answer_exp: '\uD83C\uDFC6 Sehr gut! +{{gainedExpPoints}} Erfahrungspunkte',
    toast_quiz_answer_already_done: 'Du hast die Frage bereits einmal richtig beantwortet!',
    chapters: 'Kapitel',
    requirements: 'Voraussetzungen',
    continue: 'Weitermachen',
    successfully_completed: 'Erfolgreich abgeschlossen',
    minutes: 'Minuten',
    continue_course_caption: 'Leg hier direkt wieder los!',
    new_courses: 'Neue Inhalte',
    new: 'Neu',
    new_plural: 'Neue',
    get_started: 'Loslegen'
}
