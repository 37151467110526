import { Card, CardContent, makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AnswerLetter, StyledAnswer } from '../../Components/NonLogicalComponents/StyledAnswer'
import { ChoicesForQuestionQuery, QuestionFragment } from '../../generated/graphql'
import { useTranslation } from 'react-i18next'
import { shuffleArray } from '../../Utils/shuffleArray'
import { useCustomTrans } from '../../i18n/UseCustomTrans'

const Alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G']

interface MultipleChoiceQuestionParams {
    question: QuestionFragment
    shuffledChoices: any
    activeAnswers: any
    setActiveAnswers: Function
    titlePrefix?: string
}

export function MultipleChoiceQuestion({
    question,
    shuffledChoices,
    activeAnswers,
    setActiveAnswers,
    titlePrefix
}: MultipleChoiceQuestionParams) {
    const { t } = useTranslation('finalquiz')
    const tr = useCustomTrans()

    function toggleArrayItem(arr: number[], item: number) {
        if (arr.includes(item)) {
            return arr.filter((i) => i !== item)
        } else {
            return [...arr, item]
        }
    }

    const addAnswer = (answerIndex: number) => {
        const newArray = toggleArrayItem(activeAnswers, answerIndex)
        setActiveAnswers(newArray)
    }

    return (
        <Layout>
            <TestCardWrapper>
                <Typography variant="h5">
                    {titlePrefix}
                    {tr(question?.title_i18n)}
                </Typography>
                <br />
                <Typography variant="subtitle2" component="h6">
                    {t('finalquiz:disclaimer_correct_answer')}
                </Typography>
                <br />
                {shuffledChoices.map((choice: any, index: any) => (
                    <StyledAnswer key={choice.title} onClick={() => addAnswer(index)}>
                        <AnswerLetter className={activeAnswers.includes(index) ? 'active' : ''}>
                            {Alphabet[index]}
                        </AnswerLetter>
                        <Typography variant="body1">{tr(choice.title_i18n)}</Typography>
                    </StyledAnswer>
                ))}
            </TestCardWrapper>
        </Layout>
    )
}

const Layout = styled.div`
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-flex: 1;
    /* position: relative; */
    //height: 800px;
    width: 100%;
    flex-shrink: 0;
`

const TestCardWrapper = styled.div`
    width: 100%;
    background: white;
    margin-left: auto;
    margin-right: auto;
`
