export default {
    new: 'New',
    submit: 'Submit',
    cancel: 'Cancel',
    logout: 'Logout',
    login: 'Login',
    course: 'Course',
    lection: 'Lection',
    chapter: 'Chapter',
    back: 'Back',
    next: 'Next',
    of: 'Of',
    finish: 'Finish',
    error_loading_content: 'Error loading content',
    hand: 'Hand',
    foot: 'Foot',
    profiler: 'Profiler',
    pelvis: 'Pelvis',
    products: 'Products',
    intro: 'Intro',
    getting_started: 'Get started right away:',
    continue_directly: 'Pick up where you left off:',
    continue_with_new_content: 'Get started with the new content:',
    contact_point_section_caption: 'Continue with a contact point:',
    welcome_back: 'Welcome back{{username}}!'
}
