export default {
    title: 'Final Quiz',
    overview: 'Final Tests',
    finaltest: 'Final Tests',
    explanation:
        'With these final tests you can prove what you are capable of. With each test you pass, your level increases and you receive a certificate for your achievements by email.',
    completed_at: 'Completed at',
    ongoing: 'New',
    completed: 'Completed',
    nothinghere_available: 'No more tests available. Be excited for the next content update!',
    nothinghere_completed:
        'No tests completed. Once you have unlocked a test, you can earn your certificate!',
    tooltip_locked: 'Final tests cannot be started until all contact points have been completed.',
    locked: 'Not unlocked yet',
    finalquiz_start_btn: 'Start Final Test',
    disclaimer_correct_answer:
        'Tick the correct answers. Multiple answers can be correct. A question is answered when all correct and no incorrect answers are marked.',
    quiz_passed:
        'Congratulations, you have passed the test. Your certificate will be sent to you by mail.',
    quiz_failed: "Sorry, but you failed the test. Let's try again.",
    noPoints: 'You have already passed the test once, so you will not get new points.',
    question: 'Question',
    question_plural: 'Questions',
    repeat_test: 'Repeat the test',
    evaluation: 'Evaluation'
}
