import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'

export type TitleComponentType = {
    title: string
    newChapterCount: number | undefined
}

export default function TitleComponent({
    titleInfo,
    className
}: {
    titleInfo: TitleComponentType
    className?: string
}) {
    //necessars "standard" definitions
    const { t } = useTranslation(['courses', 'base'])

    // parameter extraction
    const { title, newChapterCount } = titleInfo

    const definedNewChapterCount = newChapterCount ?? 0

    return (
        <div className={className}>
            <Typography
                gutterBottom
                variant="h6"
                style={{ display: 'flex', flexDirection: 'column' }}>
                <span>{title}</span>
                {definedNewChapterCount > 0 && (
                    <Chip
                        variant="outlined"
                        size="small"
                        label={`${definedNewChapterCount} ${
                            definedNewChapterCount > 1
                                ? t('nav:new_chapter_plural')
                                : t('nav:new_chapter')
                        }`}
                        color="primary"
                        style={{ width: 'max-content' }}
                    />
                )}
            </Typography>
        </div>
    )
}
