export default {
    information: 'INFORMATIONEN',
    service: 'SERVICE',
    followUs: 'FOLGEN SIE UNS',
    imprint: 'Impressum',
    terms: 'AGB',
    privacy: 'Datenschutzerklärung',
    returns: 'Widerrufsrecht',
    contact: 'Kontakt',
    account: 'Konto',
    newsletter: 'Newsletter'
}
