export default {
    information: 'INFORMATIE',
    service: 'SERVICE',
    followUs: 'VOLG ONS',
    imprint: 'Afdruk',
    terms: 'AVV', // "Algemene Verkoopvoorwaarden"
    privacy: 'Privacybeleid',
    returns: 'Herroepingsrecht',
    contact: 'Contact',
    account: 'Konto',
    newsletter: 'Newsletter'
}
