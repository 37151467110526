import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import SQLogo from '../../Components/NonLogicalComponents/Logo'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useRequestNewOneTimePasswordMutation } from '../../generated/graphql'
import { TopNav } from '../../Components/NonLogicalComponents/layout/TopNav'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { Buttons } from '../../Components/NonLogicalComponents/Buttons'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Copyright } from '../../Components/NonLogicalComponents/Copright'

export default function ResetPasswordForUserPage() {
    const classes = useStyles()
    const history = useHistory()
    const [requestNewOneTimePasswordMutation, { called }] = useRequestNewOneTimePasswordMutation()

    const [email, setEmail]: any = useState('')
    const { t } = useTranslation(['profile', 'login'])

    const onSubmitNewUser = async (e: any) => {
        e.preventDefault()
        try {
            await requestNewOneTimePasswordMutation({
                variables: {
                    email: email
                }
            })
        } catch (e) {}
    }

    return (
        <Container component="main" maxWidth="xs">
            <br />
            <TopNav>
                <Button onClick={() => history.push('/')}>
                    <ArrowBackIosIcon /> {t('profile:profile_overview')}
                </Button>
            </TopNav>
            <CssBaseline />
            <div className={classes.paper}>
                <SQLogo />
                <br />
                <br />
                <Typography component="h1" variant="h5">
                    {t('login:reset_password_title')}
                </Typography>
                <form className={classes.form} onSubmit={(e) => onSubmitNewUser(e)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label={t('profile:email')}
                                inputProps={{ minLength: 1, multiple: false }}
                                name="email"
                                type="email"
                                autoComplete="email"
                                onChange={(event) => {
                                    setEmail(event.target.value)
                                }}
                            />
                        </Grid>
                        <Buttons text={t('login:reset_password_btn')} />
                    </Grid>
                </form>
                {called && <p>{t('login:reset_password_succ')}</p>}
            </div>
            <Copyright />
        </Container>
    )
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: 'white',
        padding: '30px',
        borderRadius: '15px'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        padding: '10px',
        background: '#FF8D2E',
        color: 'white',
        '&:hover': {
            //you want this to be the same as the backgroundColor above
            backgroundColor: 'var(--sq-orange-hover)'
        }
    }
}))
