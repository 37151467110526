import CardMedia from '@material-ui/core/CardMedia'
import SanityTools from '../../../tools/SanityTools'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import lock from '../../../assets/lock.png'
import { Tooltip } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import LockIcon from '@material-ui/icons/Lock'
import { HelpOutline } from '@material-ui/icons'

export const useStyles = makeStyles({
    media: {
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        height: '100%',
        width: '100%'
    }
})

interface LockedCardImageComponentParams {
    isLocked?: boolean
    lockedText?: string
    lockedToolTip?: string
    img_url?: string
    title?: string
    className?: string
}

export function LockedCardImageComponent({
    isLocked,
    lockedText,
    lockedToolTip,
    img_url,
    title,
    className
}: LockedCardImageComponentParams) {
    const classes = useStyles()
    return (
        <div
            className={className}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {isLocked ? (
                <>
                    <CardMedia
                        image={SanityTools.cardImage(img_url ?? '') + '&blur=50'}
                        title={title}
                        className={classes.media}
                        style={{
                            filter: 'brightness(50%)',
                            backgroundColor: 'grey'
                        }}
                    />
                    <div
                        style={{
                            textAlign: 'center',
                            position: 'absolute',
                            zIndex: '3'
                        }}>
                        <LockIcon
                            style={{
                                color: '#fff'
                            }}
                            fontSize={'large'}
                        />
                        <Tooltip title={<Typography> {lockedToolTip}</Typography>}>
                            <Typography
                                style={{
                                    color: 'white',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                <span
                                    style={{
                                        maxWidth: '75%'
                                    }}>
                                    {lockedText}
                                    <HelpOutline style={{ paddingLeft: '0.25em' }} />
                                </span>
                            </Typography>
                        </Tooltip>
                    </div>
                </>
            ) : (
                <CardMedia
                    image={SanityTools.cardImage(img_url ?? '')}
                    title={title}
                    className={classes.media}
                />
            )}
        </div>
    )
}
