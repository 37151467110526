import React, { lazy, Suspense, useEffect, useState } from 'react'
import { DesktopLayout } from './Components/NonLogicalComponents/DektopLayout'
import { loadAuthTokenFromLocalStorage } from './Utils/setUser'
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { getRoutes, RouteDefinition } from './router/routes'
import { useTranslation } from 'react-i18next'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ScrollToTop from './router/scrollToTop'
import ResetPasswordForUserPage from './Views/account/ResetPasswordForUserPage'
import { MobileLayout } from './Components/NonLogicalComponents/MobileLayout'
import styled from 'styled-components'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'
import { parse, stringify } from 'query-string'
import VerticalMenu from './Components/NonLogicalComponents/layout/VerticalMenu'
import { HomePath } from './router/RouteConstants'
import { useSubUserDetailsSubscription } from './generated/graphql'

const LazyCreateUserPage = lazy(() => import('./Views/account/RegisterUserPage'))
const LazyLoginPage = lazy(() => import('./Views/account/LoginPage'))

export const START_DESKTOP_VERSION = 1100
export const START_DIFFERENT_CARD_TYPES = 750

export const Layout = styled.div`
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-flex: 1;
    /* position: relative; */
    //width: 100%;
    flex-shrink: 0;
`

export const RightSection = styled.div`
    width: 100%;
    max-width: 1200px;
    padding-right: 30px;
    padding-left: 30px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    flex-direction: column;
    min-height: 100vh;
    justify-items: end;
    display: flex;
    marginbottom: '56px'; /* equal to footer height */
    @media (max-width: ${START_DIFFERENT_CARD_TYPES}px) {
        padding-right: 0;
        padding-left: 1em;
    }
`

function LoginRegisterRouter() {
    return (
        <Router>
            <QueryParamProvider
                adapter={ReactRouter5Adapter}
                options={{
                    searchStringToObject: parse,
                    objectToSearchString: stringify
                }}>
                {' '}
                <Switch>
                    <Route exact path="/register">
                        <LazyCreateUserPage />
                    </Route>
                    <Route exact path="/reset_password">
                        <ResetPasswordForUserPage />
                    </Route>
                    <Route>
                        <LazyLoginPage />
                    </Route>
                </Switch>
            </QueryParamProvider>
        </Router>
    )
}

function RightSectionComponent(props: { routeDefinitions: Array<RouteDefinition> }) {
    return (
        <RightSection>
            <Suspense fallback={<div />}>
                <Switch>
                    {props.routeDefinitions.map((route) => (
                        <Route key={route.id} path={route.path}>
                            {route.component}
                        </Route>
                    ))}
                    <Route path="/">
                        <Redirect to={HomePath} />
                    </Route>
                </Switch>
            </Suspense>
            {/*Imprint is shown on the about page on mobile*/}
            {/*<FooterComponent />*/}
        </RightSection>
    )
}

export function getScreenWidth() {
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth)
    React.useEffect(() => {
        function handleResize() {
            setScreenWidth(window.innerWidth)
        }
        window.addEventListener('resize', handleResize)
    })
    return screenWidth
}

function DashboardRouter() {
    const { t } = useTranslation('nav')
    // use getRoutes here in order to make sure it is only loaded when really needed (meaning if user is logged in)
    let routeDefinitions = getRoutes(t)

    const mobileVersion = getScreenWidth() < START_DESKTOP_VERSION
    const filteredRoutes = routeDefinitions.filter((it) => !it.mobileOnly)

    return (
        <div>
            <Router>
                <ScrollToTop>
                    <Layout>
                        {mobileVersion ? (
                            <MobileLayout routeDefinitions={routeDefinitions}>
                                <RightSectionComponent routeDefinitions={routeDefinitions} />
                            </MobileLayout>
                        ) : (
                            <DesktopLayout
                                drawer={[
                                    <VerticalMenu
                                        routes={filteredRoutes}
                                        key="meinMenu"
                                        changeMenuVisibility={undefined}
                                    />
                                ]}></DesktopLayout>
                        )}
                        <RightSectionComponent routeDefinitions={routeDefinitions} />
                    </Layout>
                </ScrollToTop>
            </Router>
        </div>
    )
}

function App() {
    let profileOnly = loadAuthTokenFromLocalStorage()

    let { data: user, loading: userRegistrationOngoing } = useSubUserDetailsSubscription()
    console.log('user', user)
    const showLoginPages = !profileOnly

    console.log('profileOnly', profileOnly)

    return (
        <React.Fragment>
            <div className="App">
                {userRegistrationOngoing ? (
                    <p>Loading</p>
                ) : showLoginPages ? (
                    <LoginRegisterRouter />
                ) : (
                    <DashboardRouter />
                )}
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </React.Fragment>
    )
}

export default App
