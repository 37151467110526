import React from 'react'
import styled from 'styled-components'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import LockIcon from '@material-ui/icons/Lock'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'

const Step = styled.div`
    width: 100%;
    border-radius: 10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 10px;

    &:hover {
        cursor: pointer;
    }
`

const StepLeftSection = styled.div`
    display: flex;
    align-items: center;
`

const StepLocked = styled.div`
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    color: gray;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    &:hover {
        cursor: pointer;
    }
`

export function PlayListStep({ done, title, minutes, onClicked, locked, active }: any) {
    const StepIcon = done ? (
        <CheckCircleOutlineIcon style={{ marginRight: '10px' }} />
    ) : (
        <PlayCircleOutlineIcon style={{ marginRight: '10px' }} />
    )

    return (
        <>
            {locked ? (
                <StepLocked>
                    <StepLeftSection>
                        <LockIcon style={{ marginRight: '20px' }} />
                        {title}
                    </StepLeftSection>

                    {minutes && <p>{minutes} min</p>}
                </StepLocked>
            ) : (
                <Step
                    onClick={() => onClicked()}
                    style={
                        active
                            ? // Active chapter is highlighted with orange background color
                              { background: 'var(--sq-orange)' }
                            : !done
                            ? // Unfinished chapter font color should be grey (white background by default)
                              { color: 'grey' }
                            : // Finished chapter have dark grey background color
                              { background: 'rgb(58,58,58)' }
                    }>
                    <StepLeftSection>
                        {StepIcon} {title}
                    </StepLeftSection>
                    {minutes && <p>{minutes} min</p>}
                </Step>
            )}
        </>
    )
}
