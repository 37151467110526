import { useTranslation } from 'react-i18next'
import * as Sentry from '@sentry/react'

export function useCustomTrans() {
    const { i18n, t } = useTranslation('base')
    const language = i18n.language

    return (object: any) => {
        if (!object) return ''
        // The field is directly passed into our function
        const translatedObject = object[language]
        const englishObject = object['en']

        if (translatedObject) {
            return translatedObject
        } else if (englishObject) {
            return englishObject
        } else {
            Sentry.captureException(`Missing ${language} translation for object `, object)
            return '❌ ' + t('error_loading_content')
        }
    }
}
