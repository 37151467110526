import styled from 'styled-components'

export const StyledAnswer = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;

    padding: 10px;
`
export const AnswerLetter = styled.div`
    background: white;
    border: 2px solid var(--sq-orange);
    margin-right: 20px;
    color: var(--sq-orange);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    flex-shrink: 0;

    &.active {
        color: white !important;
        background: var(--sq-orange) !important;
    }
`
