export default {
    rememberMe: 'Eingeloggt bleiben',
    title: 'Händler Login',
    username: 'Kundennummer',
    password: 'Händler Passwort',
    usePW: 'Passwort benutzen',
    note: 'Hinweis',
    error_vendor:
        'Die Zugangsdaten sind nicht gültig. Bitte versuchen Sie es erneut oder setzen Sie Ihr Passwort zurück.',
    welcome: 'Willkommen!',
    label_b2b_login: 'B2B Login',
    label_profile_login: 'Profil Login',
    profile_email: 'E-Mail Addresse',
    profile_password: 'Passwort',
    register_text: 'Du hast noch kein Konto?',
    error_email_exists:
        'Ein Profil mit dieser E-Mail ist bereits registriert. Bitte verwende eine andere E-Mail oder melde dich an.',
    error_vendor_id_not_found:
        'Die Kundennummer ist nicht gültig oder gesperrt. Bitte wende dich an den Support.',
    create_new_profile: 'Neues Profil anlegen',
    info_customer_numbers:
        'Bitte beachte die aktuelle Umstellung der Kundennummern. Die neue Version hat "00" am Ende.',
    reset_password_question: 'Password vergessen?',
    reset_password_btn: 'Neues Passwort anfordern',
    reset_password_title: 'Password Reset',
    reset_password_succ:
        'Falls die Email Addresse korrekt war, findest du ein neues Passwort in deinem Postfach.'
}
