export default {
    installation: 'Tips for mounting',
    videos: {
        0: '797d0efd4dea165704b8b5b1b0100c3f', // SQlab_Adjustment_Saddle_EN
        1: 'e80629075fb27c17408f30a57c611777', // Montage_Anbindung_613R_EN
        2: '75765ec99672185c3ff70890fee700f4', // 712_Montage_EN_YouTube
        3: 'f2e01ff32dffa3f9162199b94cc24bfa' // Pflegehinweise US
    },
    thumbnails: {
        0: 'https://sqlab-public.s3.eu-central-1.wasabisys.com/video_thumbnails/iqlab/SQlab.youtube.Thumbnail.Einstelltipps.Sattel.0521.US.jpg', // SQlab_Einstelltipps_Sattel
        1: 'https://sqlab-public.s3.eu-central-1.wasabisys.com/video_thumbnails/iqlab/SQlab.youtube.Thumbnail.613r.integrated.bottle.holder.0421.US.jpg', // Montage_Anbindung_613R_DE
        2: 'https://sqlab-public.s3.eu-central-1.wasabisys.com/video_thumbnails/iqlab/SQlab.youtube.Thumbnail.Montageanleitungen.09202.jpg', // 712_Montage_DE_YouTube
        3: 'https://sqlab-public.s3.eu-central-1.wasabisys.com/video_thumbnails/iqlab/SQlab.youtube.Thumbnail.pflegetipps.sattel.1022.US.jpg' // Pflegehinweise
    }
}
