export default {
    rememberMe: 'Ingelogd blijven',
    title: 'Inloggen handelaren',
    username: 'Klantnummer',
    password: 'Dealer wachtwoord',
    usePW: 'Wachtwoord gebruiken',
    note: 'Noot',
    error_vendor: 'De toegangsgegevens zijn ongeldig. Probeer het opnieuw of stel uw wachtwoord opnieuw in.',
    welcome: 'Welkom!',
    label_b2b_login: 'B2B Inloggen',
    label_profile_login: 'Profiel aanmelden',
    profile_email: 'E-mail adres',
    profile_password: 'Wachtwoord',
    register_text: 'Heb je nog geen account?',
    error_email_exists: 'Een profiel met dit e-mailadres is al geregistreerd. Gebruik een ander e-mailadres of meld u aan.',
    error_vendor_id_not_found: 'Het klantnummer is niet geldig of geblokkeerd. Neem contact op met de ondersteuning.',
    create_new_profile: 'Nieuw profiel aanmaken',
    info_customer_numbers: 'Let op de huidige wijziging van de klantnummers. De nieuwe versie heeft "00" aan het eind.',
    reset_password_question: 'Wachtwoord vergeten?',
    reset_password_btn: 'Nieuw wachtwoord aanvragen',
    reset_password_title: 'Wachtwoord Reset',
    reset_password_succ: 'Als het e-mailadres correct was, vindt u een nieuw wachtwoord in uw mailbox.'
}
